import React, { useEffect } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { useTheme } from '@mui/material'
import { useDialog } from '@contexts/dialogContext'
import { useUserContext } from '@contexts/userContext'
import {
    NavigationProp,
    ParamListBase,
    useNavigation,
} from '@react-navigation/native'
import LandingPage from '@screens/LandingPage'
import { Sessions } from '@screens/loggedIn/Sessions/Sessions'

const Onboarding = ({ navigation, route }) => {
    const theme = useTheme()
    const { openLoginDialog, setShowLoginModal, openOnboardingDialog } =
        useDialog()
    const { user, isTutor } = useUserContext()

    useEffect(() => {
        if (isTutor) {
            openOnboardingDialog()
        }
    }, [isTutor])

    if (user) {
        return <Sessions navigation={navigation} />
    } else {
        return <LandingPage navigation={navigation} route={route} />
    }
}

export default Onboarding
