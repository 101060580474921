import React from 'react'
import { Grid2, Box, Typography } from '@mui/material'
import { useTheme, useMediaQuery } from '@mui/material'
import { useStyles } from '@utils/styles'
import '@assets/images/landing-page/247access.svg'

interface DiscoverItemProps {
    icon: string
    title: string
    text: string
}

const DiscoverItem: React.FC<DiscoverItemProps> = ({ icon, title, text }) => {
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const defaultStyles = useStyles()

    return (
        <Grid2
            container
            sx={{ mb: 2 }}
            spacing={{ xs: 2, md: 4 }}
            alignItems={{
                xs: 'flex-start',
                sm: 'center',
            }}
            flexDirection={{
                xs: 'column',
                sm: 'row',
            }}
        >
            <Grid2
                size={{
                    xs: 3,
                }}
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                }}
            >
                <Box
                    component="img"
                    sx={{
                        width: { xs: '80%' },
                        mt: { xs: 2, sm: 0 },
                    }}
                    src={require(`@assets/images/landing-page/${icon}`)}
                />
            </Grid2>

            <Grid2 size={{ xs: 12, sm: 9 }}>
                <Typography
                    fontSize={{
                        xs: '1.4rem',
                        sm: '1.8rem',
                        md: '1.4rem',
                        lg: '1.8rem',
                    }}
                    fontFamily={defaultStyles.boldFont}
                    letterSpacing="-1.08px"
                >
                    {title}
                </Typography>
                <Typography
                    fontSize={{
                        xs: '1.2rem',
                        sm: '1.2rem',
                        lg: '1.4rem',
                    }}
                    fontFamily="Poppins_500Medium"
                    letterSpacing="-1.8px"
                >
                    {text}
                </Typography>
            </Grid2>
        </Grid2>
    )
}

export default DiscoverItem
