import { Box } from '@mui/material'
import React from 'react'
import { Footer } from '@components/Footer'
import PressHero from './PressHero'
import GetInTouch from './GetInTouch'

const Press: React.FC = () => {
    return (
        <Box
            sx={{
                flex: 1,
                backgroundColor: '#FCFCFC',
                width: '100%',
                overflowY: 'auto',
            }}
        >
            <PressHero />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 6,
                }}
            >
                <Box
                    component="img"
                    src={require('@assets/images/press/letters.png')}
                    alt="landing-page-image"
                    sx={{
                        height: { md: 232, xs: 200 },
                    }}
                />
            </Box>
            <GetInTouch />

            <Footer />
        </Box>
    )
}

export default Press
