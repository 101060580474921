import { Box, Grid2, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import React from 'react'

const ExploreAI: React.FC = () => {
    const defaultStyles = useStyles()
    const { primary } = useColorsContext()
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const isLg = useMediaQuery(theme.breakpoints.up('lg'))
    const isSm = useMediaQuery(theme.breakpoints.down('sm'))
    const items = [
        'AI Co-tutor that instantly generates interactive relevant problems, solutions, examples, and diagrams in a live session',
        'Generate lesson plans for future lessons',
        'Generate interactive post-lesson homework',
        'Generate interactive quizzes',
        'Automated AI grading',
        'Use our AI course builder to build your own courses  effortlessly',
    ]
    return (
        <Box
            sx={{
                backgroundColor: primary,
            }}
        >
            <Grid2
                container
                sx={{
                    p: 4,
                    maxWidth: 1280,
                    margin: 'auto',
                    overflow: 'hidden',
                }}
                spacing={4}
            >
                <Grid2
                    size={{ xs: 12, md: 5 }}
                    sx={{
                        display: 'flex',
                        flexDirection: isMd || isSm ? 'column' : 'row',
                        alignItems: 'center',
                        flex: 1,
                    }}
                >
                    <Typography
                        variant={isLg ? 'h2' : isMd ? 'h3' : 'h4'}
                        color="#FFF"
                        lineHeight={1.6}
                        fontFamily={defaultStyles.titleFont}
                        letterSpacing="-1.8px"
                    >
                        Explore novel
                        <span style={{ color: '#000' }}> AI tools </span> that
                        will elevate your{' '}
                        {!isMd && !isSm && 'teaching experience'}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        {(isMd || isSm) && (
                            <Typography
                                variant={isLg ? 'h2' : isMd ? 'h3' : 'h4'}
                                color="#FFF"
                                fontFamily={defaultStyles.titleFont}
                                lineHeight={1.6}
                            >
                                teaching experience
                            </Typography>
                        )}
                        <Box
                            component="img"
                            sx={{
                                maxHeight: {
                                    xs: 115,
                                    md: 130,
                                    lg: 170,
                                },
                            }}
                            src={require('@assets/images/become-a-tutor/light-bulb.png')}
                        />
                    </Box>
                </Grid2>
                <Grid2
                    size={{ xs: 12, md: 7 }}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                    }}
                >
                    {items.map((item) => (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                mb: { xs: 1, sm: 2, lg: 0 },
                            }}
                        >
                            <Box
                                component="img"
                                sx={{
                                    width: { md: 40, xs: 30 },
                                    mt: 0.5,
                                    mr: 2,
                                }}
                                src={require('@assets/images/become-a-tutor/check.png')}
                            />
                            <Typography
                                variant={isMd ? 'h5' : 'h6'}
                                fontFamily={defaultStyles.boldFont}
                                color="#FFF"
                                letterSpacing="-1.08px"
                            >
                                {item}
                            </Typography>
                        </Box>
                    ))}
                </Grid2>
            </Grid2>
        </Box>
    )
}

export default ExploreAI
