import React from 'react'
import {
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    useTheme,
    useMediaQuery,
    Grid2,
} from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'

const GetInTouch = () => {
    const { link, primaryLight } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const isLg = useMediaQuery(theme.breakpoints.up('lg'))

    return (
        <Box
            sx={{
                p: { md: 6, sm: 2, xs: 2 },
                maxWidth: 1280,
                margin: 'auto',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Grid2
                container
                borderRadius={6}
                sx={{
                    backgroundColor: primaryLight,
                    py: { sm: 4, xs: 2 },
                    px: { md: 6, sm: 4, xs: 2 },
                    width: '100%',
                }}
                spacing={{ sm: 8, xs: 0 }}
            >
                <Grid2 size={{ lg: 6, sm: 8, xs: 12 }}>
                    <Typography
                        fontFamily={defaultStyles.titleFont}
                        sx={{
                            mb: 2,
                        }}
                        letterSpacing="-2.52px"
                        fontSize={{
                            xs: '2.2rem',
                            sm: '2.5rem',
                            md: '3rem',
                            lg: '3rem',
                        }}
                    >
                        Get in touch
                    </Typography>
                    <Typography
                        fontFamily={defaultStyles.mediumFont}
                        letterSpacing="-1.8px"
                        fontSize={{
                            xs: '1rem',
                            sm: '1.2rem',
                            md: '1.4rem',
                            lg: '1.4rem',
                        }}
                    >
                        Please direct all press and brand partnership inquiries
                        to{' '}
                        <a
                            href="mailto:press@tutred.com"
                            style={{
                                textDecoration: 'none',
                            }}
                        >
                            press@tutred.com,
                        </a>{' '}
                        and all influencer inquiries to{' '}
                        <a
                            href="mailto:influencers@tutred.com"
                            style={{
                                textDecoration: 'none',
                            }}
                        >
                            influencers@tutred.com.
                        </a>
                    </Typography>
                </Grid2>
                <Grid2
                    size={{ lg: 6, sm: 4, xs: 12 }}
                    display="flex"
                    direction="row"
                    justifyContent="flex-end"
                >
                    <Box
                        component="img"
                        src={require('@assets/images/press/mailbox.png')}
                        alt="landing-page-image"
                        sx={{
                            height: { lg: 232, sm: 186, xs: 120 },
                            objectFit: 'contain',
                        }}
                    />
                </Grid2>
            </Grid2>
        </Box>
    )
}

export default GetInTouch
