import { Link, LinkProps } from '@mui/material'
import { useStyles } from '@utils/styles'

const FooterLink: React.FC<LinkProps> = (props) => {
    const defaultStyles = useStyles()

    return (
        <Link
            {...props}
            underline="hover"
            sx={{
                fontFamily: defaultStyles.mediumFont,
                fontSize: '.9rem',
                color: '#000',
                letterSpacing: '-1.08px',
            }}
        />
    )
}

export default FooterLink
