import { useState, useEffect, createContext, useContext } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useColorScheme } from 'react-native'

export type Styles = {
    radius: number
    boldFont: string
    titleFont: string
    mediumFont: string
    regularFont: string
    lightFont: string
    buttonRadius: number
}

export type Colors = {
    primary: string
    primaryVariant: string
    red: string
    redVariant: string
    background: string
    backgroundVariant: string
    text: string
    textVariant: string
    textSecondary: string
    border: string
    yellow: string
    green: string
    purple: string
    darkMode: boolean
    grey: string
    primaryLight: string
    textInverse: string
    toggleDarkMode: () => void
    setDarkMode: (darkMode: boolean) => void
    disabled: string
    primaryDark: string
    primaryExtraLight: string
    link: string
}

export const useStyles = (): Styles => {
    return {
        radius: 12,
        boldFont: 'Poppins_700Bold',
        titleFont: 'Poppins_600SemiBold',
        mediumFont: 'Poppins_500Medium',
        regularFont: 'Poppins_400Regular',
        lightFont: 'Poppins_300Light',
        buttonRadius: 100,
    }
}

const colorsContext = createContext<Colors>({})

// Export colors as a module
export const ColorsContextProvider = ({
    children,
}: {
    children: React.ReactNode
}) => {
    // Load dark mode from settings, disabled for now
    // const [darkMode, setDarkMode] = useState(useColorScheme() === "dark");
    const [darkMode, setDarkMode] = useState<boolean>(false)
    const [primary, setPrimary] = useState<string>('')
    const [primaryLight, setPrimaryLight] = useState<string>('')
    const [primaryExtraLight, setPrimaryExtraLight] = useState<string>('')
    const [primaryDark, setPrimaryDark] = useState<string>('#4039FE')
    const [primaryVariant, setPrimaryVariant] = useState<string>('')
    const [red, setRed] = useState<string>('')
    const [redVariant, setRedVariant] = useState<string>('')
    const [background, setBackground] = useState<string>('')
    const [backgroundVariant, setBackgroundVariant] = useState<string>('')
    const [text, setText] = useState<string>('')
    const [textInverse, setTextInverse] = useState<string>('')
    const [textVariant, setTextVariant] = useState<string>('')
    const [textSecondary, setTextSecondary] = useState<string>('')
    const [border, setBorder] = useState<string>('')
    const [yellow, setYellow] = useState<string>('')
    const [green, setGreen] = useState<string>('')
    const [purple, setPurple] = useState<string>('')
    const [grey, setGrey] = useState<string>('#63626a')
    const [disabled, setDisabled] = useState<string>('#bfbfbf')
    const [link, setLink] = useState<string>('#026FFF')
    // useEffect(() => {
    //     // Load settings from local storage,  TODO:// update this to save and load the setting for individual users in firestore.
    //     AsyncStorage.getItem("darkMode")
    //         .then((localDarkMode) => {
    //             console.log("localDarkMode", Boolean(localDarkMode))
    //             if (localDarkMode) {
    //                 setDarkMode(localDarkMode === "true");
    //             }
    //         });
    // });

    useEffect(() => {
        updateColors()
    }, [darkMode])

    const updateColors = () => {
        // Update colors based on dark mode
        setPrimary(darkMode ? '#0060DE' : '#0060DE')
        setPrimaryLight(darkMode ? '#262626' : '#d9e9ff')
        setPrimaryExtraLight(darkMode ? '#212121' : 'rgba(0, 110, 255, 0.07)')
        setPrimaryVariant(darkMode ? '#3b37fe' : '#3b37fe')
        setRed(darkMode ? '#cc0000' : '#cc0000')
        setRedVariant(darkMode ? '#090909' : '#191919')
        setBackground(darkMode ? '#000000' : '#FEFCFC')
        setBackgroundVariant(darkMode ? '#090909' : '#FFFFFF')
        setText(darkMode ? '#FFFFFF' : '#000000')
        setTextInverse(darkMode ? '#000000' : '#FFFFFF')
        setTextVariant(darkMode ? '#d6d6d6' : '#191919')
        setTextSecondary('#8c8c8c')
        setBorder(darkMode ? '#121212' : '#EEEEEE')
        setYellow(darkMode ? '#FFB347' : '#FFB347')
        setGreen(darkMode ? '#234EDB' : '#234EDB')
        setPurple(darkMode ? '#866aeb' : '#866aeb')
    }

    const toggleDarkMode = () => {
        // Save dark mode to local storage
        AsyncStorage.setItem('darkMode', !darkMode ? 'true' : 'false')
        setDarkMode(!darkMode)
    }

    return (
        <colorsContext.Provider
            value={{
                primary,
                primaryVariant,
                red,
                redVariant,
                background,
                backgroundVariant,
                text,
                textVariant,
                textSecondary,
                border,
                yellow,
                green,
                purple,
                darkMode,
                grey,
                primaryLight,
                textInverse,
                toggleDarkMode,
                setDarkMode,
                disabled,
                primaryDark,
                primaryExtraLight,
                link,
            }}
        >
            {children}
        </colorsContext.Provider>
    )
}

export function useColorsContext() {
    return useContext(colorsContext)
}
