import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useNavigation, useRoute } from '@react-navigation/native'
import { articles } from '@screens/Help/data'
import { matchSorter } from 'match-sorter'
import SearchResult from './SearchResult'
import { useColorsContext, useStyles } from '@utils/styles'
import FilterButton from '../FilterButton'

const SearchResults = () => {
    const defaultStyles = useStyles()
    const { textSecondary } = useColorsContext()
    const params = useRoute().params

    const navigation = useNavigation()

    const [selectedFilter, setSelectedFilter] = useState<
        'all' | 'student' | 'tutor'
    >('all')
    const [results, setResults] = useState([])

    useEffect(() => {
        let filterOptions = matchSorter(articles, params?.search, {
            keys: ['title'],
        })

        filterOptions = filterOptions.filter(
            (option) =>
                selectedFilter === 'all' || option.type === selectedFilter
        )
        setResults(filterOptions)
    }, [params, selectedFilter])

    return (
        <Box>
            <Typography
                variant="body2"
                color={textSecondary}
                fontFamily={defaultStyles.titleFont}
                sx={{ mb: 2 }}
            >
                FILTER RESULTS
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
                <FilterButton
                    label="All"
                    isSelected={selectedFilter === 'all'}
                    onClick={() => {
                        setSelectedFilter('all')
                    }}
                />
                <FilterButton
                    label="Tutors"
                    isSelected={selectedFilter === 'tutor'}
                    onClick={() => {
                        setSelectedFilter('tutor')
                    }}
                />
                <FilterButton
                    label="Students"
                    isSelected={selectedFilter === 'student'}
                    onClick={() => {
                        setSelectedFilter('student')
                    }}
                />
            </Box>

            {results?.length > 0 ? (
                <Box sx={{ mt: 2, mb: 3 }}>
                    {results?.map((result) => {
                        return <SearchResult result={result} />
                    })}
                </Box>
            ) : (
                <Box sx={{ perspective: 2 }}>
                    <Typography
                        variant="body1"
                        color={textSecondary}
                        fontFamily={defaultStyles.titleFont}
                    >
                        No results found.
                    </Typography>
                </Box>
            )}
        </Box>
    )
}

export default SearchResults
