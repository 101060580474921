import React from 'react'
import {
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListSubheader,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import AuthHeaderLink from './AuthHeaderLink'
import { useDialog } from '@contexts/dialogContext'

const AuthHeaderDrawer = ({ showDrawer, setShowDrawer }) => {
    const { openLoginDialog, openSignupDialog } = useDialog()
    return (
        <Drawer
            open={showDrawer}
            onClose={() => setShowDrawer(false)}
            anchor="right"
            variant="temporary"
            sx={{
                borderRadius: 0,

                '& .MuiDrawer-paper': {
                    borderRadius: 0,
                    width: 226,
                },
            }}
        >
            <List>
                <ListItem
                    sx={{
                        justifyContent: 'space-between',
                    }}
                >
                    <AuthHeaderLink
                        onClick={() => {
                            setShowDrawer(false)
                            openLoginDialog()
                        }}
                    >
                        Login
                    </AuthHeaderLink>

                    <IconButton
                        onClick={() => {
                            setShowDrawer(false)
                        }}
                    >
                        <CloseIcon sx={{ color: '#000' }} />
                    </IconButton>
                </ListItem>
                <Divider
                    sx={{
                        marginBottom: 2,
                    }}
                />
                <ListItem>
                    <AuthHeaderLink href="/tutors">Find Tutors</AuthHeaderLink>
                </ListItem>
                <ListItem>
                    <AuthHeaderLink
                        onClick={() => {
                            openSignupDialog('student')
                        }}
                    >
                        Sign up
                    </AuthHeaderLink>
                </ListItem>
                <ListItem>
                    <AuthHeaderLink
                        onClick={() => {
                            openSignupDialog('tutor')
                        }}
                    >
                        Become a Tutor
                    </AuthHeaderLink>
                </ListItem>

                <ListItem>
                    <AuthHeaderLink href="/help">Help</AuthHeaderLink>
                </ListItem>
            </List>
        </Drawer>
    )
}

export default AuthHeaderDrawer
