import React from 'react'
import {
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import { useDialog } from '@contexts/dialogContext'

const BecomeATutorHero = () => {
    const { primary } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))
    const { openSignupDialog } = useDialog()

    return (
        <Box
            sx={{
                backgroundColor: primary,
            }}
        >
            <Card
                sx={{
                    p: { xs: 1, sm: 3, md: 4 },
                    position: 'relative',
                    overflow: 'hidden',
                    borderRadius: 0,
                    zIndex: 99,
                    maxWidth: 1280,
                    margin: 'auto',
                    backgroundColor: 'transparent',
                }}
                elevation={0}
            >
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: isSm ? 'row' : 'column',
                        pb: 0,
                        ':last-child': {
                            paddingBottom: {
                                xs: 1,
                                sm: 0,
                            },
                        },
                        '& .MuiCardContent-root': {
                            pb: 0,
                        },
                    }}
                >
                    <Box sx={{ flex: 9, zIndex: 99 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#FFF',
                                    mb: 2,
                                    fontSize: {
                                        xs: '2rem',
                                        sm: '2.5rem',
                                        md: '3.5 rem',
                                        lg: '4rem',
                                        xl: '5rem',
                                    },
                                    lineHeight: 1.5,
                                    letterSpacing: '-1.8px',
                                }}
                                fontFamily={defaultStyles.titleFont}
                                variant="h2"
                            >
                                Get paid for <br /> sharing your <br />{' '}
                                knowledge
                            </Typography>
                            {!isSm && (
                                <Box
                                    component="img"
                                    src={require('@assets/images/become-a-tutor/hero.png')}
                                    alt="landing-page-image"
                                    sx={{
                                        width: '40%',
                                        maxHeight: 150,
                                        objectFit: 'contain',
                                    }}
                                />
                            )}
                        </Box>

                        <Typography
                            sx={{
                                color: '#FFF',
                                fontSize: {
                                    xs: '1.5rem',
                                    sm: '1.75rem',
                                    md: '2rem',
                                },

                                letterSpacing: '-1.08px',
                            }}
                            fontFamily="Poppins_500Medium"
                            variant="h4"
                        >
                            Monetize your expertise and start teaching today!
                        </Typography>
                        <Box
                            sx={{
                                mt: 4,
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                gap: 2,
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    fontFamily: defaultStyles.boldFont,
                                    fontSize: {
                                        xs: '1rem',
                                        sm: '1rem',
                                        md: '1.25rem',
                                    },
                                    mt: 2,
                                    px: { xs: 2, sm: 3 },
                                    height: { xs: 48, sm: 50, md: 52 },
                                    backgroundColor: '#FFF',
                                    border: `3px solid black`,
                                    color: '#000',
                                    borderRadius: '12px',
                                    width: { xs: '100%', sm: 'auto' },
                                }}
                                onClick={() => openSignupDialog('tutor')}
                            >
                                Make a profile now
                            </Button>
                        </Box>
                    </Box>
                    {isSm && (
                        <Box
                            sx={{
                                flex: 7,
                                display: 'flex',
                                justifyContent: 'center',
                                minHeight: isSm ? 250 : 150,
                            }}
                        >
                            <Box
                                component="img"
                                src={require('@assets/images/become-a-tutor/hero.png')}
                                alt="landing-page-image"
                                sx={{
                                    width: '100%',
                                    maxHeight: 500,
                                    objectFit: 'contain',
                                }}
                            />
                        </Box>
                    )}
                </CardContent>
            </Card>
        </Box>
    )
}

export default BecomeATutorHero
