import React, { useEffect, useMemo, useState } from 'react'
import { useColorsContext, useStyles } from '../../utils/styles'

import {
    NavigationProp,
    ParamListBase,
    useNavigation,
    useRoute,
} from '@react-navigation/native'

import {
    Box,
    Button,
    Container,
    Drawer,
    IconButton,
    List,
    ListItem,
    Link,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { useDialog } from '@contexts/dialogContext'
import MenuIcon from '@mui/icons-material/Menu'
import AuthHeaderLink from './AuthHeaderLink'
import AuthHeaderDrawer from './AuthHeaderDrawer'

interface AuthHeaderProps {
    variant: 'primaryLight' | 'primary'
}

const AuthHeader: React.FC<AuthHeaderProps> = ({
    variant = 'primaryLight',
}) => {
    // Appearance
    const { text, primaryLight, primary } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const { openSignupDialog, openLoginDialog } = useDialog()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))
    const navigation: NavigationProp<ParamListBase> = useNavigation()
    const route = useRoute()
    const isHome = route.name === 'LandingPage'

    const [showDrawer, setShowDrawer] = useState(false)

    const variantStyles = useMemo(() => {
        if (variant === 'primary') {
            return {
                backgroundColor: primary,
                color: '#FFF',
                logo: 'logo-white.svg',
            }
        } else {
            return {
                backgroundColor: primaryLight,
                color: '#000',
                logo: 'logo.svg',
            }
        }
    }, [variant, primary, primaryLight])

    return (
        <Box
            sx={{
                backgroundColor: variantStyles.backgroundColor,
            }}
        >
            <Container
                maxWidth="xl"
                sx={{
                    height: { xs: 60, sm: 80, lg: 100 },
                    display: 'flex',

                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: { xs: 1, sm: 2, md: 4 },
                    }}
                >
                    <Link href="/">
                        <Box
                            src={require(
                                `@assets/images/${variantStyles.logo}`
                            )}
                            component="img"
                            sx={{
                                maxHeight: { xs: 40, sm: 60 },
                                width: 'auto',
                            }}
                        />
                    </Link>
                    <AuthHeaderLink color={variantStyles.color} href="/tutors">
                        Find tutors
                    </AuthHeaderLink>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { md: 'row-reverse', sm: 'row' },
                        alignItems: 'center',
                        gap: { xs: 1, sm: 2, md: 4 },
                    }}
                >
                    <Button
                        variant="outlined"
                        color="info"
                        size="small"
                        onClick={() => {
                            openSignupDialog('student')
                        }}
                        sx={{
                            paddingX: { xs: 2, sm: 6, md: 8 },
                            height: { xs: '100%', md: 54 },
                            fontSize: { xs: '1rem', md: '1.2rem' },
                        }}
                    >
                        Sign up
                    </Button>
                    {isMd ? (
                        <>
                            <AuthHeaderLink
                                color={variantStyles.color}
                                onClick={() => {
                                    openSignupDialog('tutor')
                                }}
                            >
                                Become a Tutor
                            </AuthHeaderLink>
                            <AuthHeaderLink
                                color={variantStyles.color}
                                onClick={() => {
                                    openLoginDialog()
                                }}
                            >
                                Login
                            </AuthHeaderLink>
                        </>
                    ) : (
                        <>
                            <IconButton
                                onClick={() => {
                                    setShowDrawer(true)
                                }}
                            >
                                <MenuIcon
                                    sx={{
                                        color: variantStyles.color,
                                    }}
                                />
                            </IconButton>
                        </>
                    )}
                </Box>

                <AuthHeaderDrawer
                    setShowDrawer={setShowDrawer}
                    showDrawer={showDrawer}
                />
            </Container>
        </Box>
    )
}

export default AuthHeader
