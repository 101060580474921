import { subjectIcons, subjects } from '@config/subjects'
import SearchIcon from '@icons/SearchIcon'
import {
    Autocomplete,
    Box,
    InputAdornment,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import {
    NavigationProp,
    ParamListBase,
    useNavigation,
} from '@react-navigation/native'
import { useColorsContext, useStyles } from '@utils/styles'
import React from 'react'

const SearchTutors = () => {
    const defaultStyles = useStyles()
    const { primary, primaryLight } = useColorsContext()
    const theme = useTheme()
    const [search, setSearch] = React.useState('')
    const navigation: NavigationProp<ParamListBase> = useNavigation()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Box
            sx={{
                backgroundColor: primaryLight,
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    mt: 6,
                    display: 'flex',
                    pt: { sm: 4, xs: 2 },
                    px: { md: 6, sm: 4, xs: 2 },
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: 1280,
                    margin: 'auto',
                }}
            >
                <Typography
                    fontFamily={defaultStyles.boldFont}
                    fontSize={{ xs: '1.5rem', sm: '2.5rem', md: '3rem' }}
                    letterSpacing="-1.08px"
                >
                    Browse <span style={{ color: primary }}>tutors</span> by
                    subject!
                </Typography>
                <Typography
                    fontFamily={defaultStyles.titleFont}
                    fontSize={{ xs: '1rem', sm: '1.4rem', md: '1.6rem' }}
                    letterSpacing="-1.08px"
                >
                    Choose from over 50 core subjects to learn{' '}
                </Typography>
                <Box
                    sx={{
                        position: 'relative',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        pt: { sm: 4, xs: 2 },
                        pb: 2,
                    }}
                >
                    <Box
                        sx={{
                            width: { xs: '100%', sm: '80%' },
                            margin: 'auto',
                        }}
                    >
                        <Autocomplete
                            value={search}
                            options={subjects}
                            getOptionLabel={(option) => option}
                            onChange={(e, value) => {
                                setSearch(value)
                                if (value) {
                                    navigation.navigate('Tutors', {
                                        screen: 'TutorList',
                                        params: { subject: value },
                                    })
                                }
                            }}
                            sx={{
                                '& .Mui-Autocomplete-root': {
                                    zIndex: 9999,
                                },
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <Box
                                        {...props}
                                        key={option}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            p: 1,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: 28,
                                                height: 28,
                                                backgroundColor:
                                                    theme.palette.grey[800],
                                                WebkitMask: `url(${subjectIcons[option]}) no-repeat center `,
                                                mask: `url(${subjectIcons[option]}) no-repeat center `,
                                                maskSize: 'contain',
                                                WebkitMaskSize: 'contain',
                                                mr: 2,
                                            }}
                                        />
                                        <Typography>{option}</Typography>
                                    </Box>
                                )
                            }}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    placeholder="Search a subject"
                                    slotProps={{
                                        input: {
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start"
                                                    sx={{ ml: 1 }}
                                                >
                                                    <SearchIcon size={24} />
                                                </InputAdornment>
                                            ),
                                            style: {
                                                backgroundColor: 'white',
                                                height: 72,
                                            },
                                        },
                                    }}
                                    {...params}
                                ></TextField>
                            )}
                        />
                    </Box>
                </Box>
                <Box
                    component="img"
                    src={require('@assets/images/landing-page/browse-tutors.svg')}
                    alt="Search Tutors"
                    sx={{
                        width: { lg: 400, md: 280, sm: 220, xs: 150 },
                        height: 'auto',
                    }}
                />
            </Box>
        </Box>
    )
}

export default SearchTutors
