import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { NavigationProp } from '@react-navigation/native'
import { Help } from '@screens/Help'
import HelpArticle from '@screens/Help/HelpArticle'

export type HelpStackParamList = {
    HelpArticle: {
        slug: string
        navigation: any
        route: any
    }
}

const Stack = createNativeStackNavigator<HelpStackParamList>()

export type HelpStackNavigationProp = NavigationProp<HelpStackParamList>

const HelpStack = () => {
    return (
        <Stack.Navigator
            screenOptions={{
                headerShown: false,
            }}
        >
            <Stack.Screen
                name="Help"
                component={Help}
                options={{ title: 'Help' }}
            />
            <Stack.Screen name="HelpArticle" component={HelpArticle} />
        </Stack.Navigator>
    )
}

export default HelpStack
