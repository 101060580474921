import React from 'react'
import { Typography, Box } from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'

const PressHero = () => {
    const { primaryLight, link } = useColorsContext()
    const defaultStyles = useStyles()

    return (
        <Box
            sx={{
                backgroundColor: primaryLight,
                pt: 6,
                pb: 4,
            }}
        >
            <Box
                sx={{
                    maxWidth: 1280,
                    margin: 'auto',
                    overflow: 'hidden',
                }}
            >
                <Typography
                    fontFamily={defaultStyles.titleFont}
                    textAlign="center"
                    letterSpacing="-2.52px"
                    fontSize={{
                        lg: '3.8rem',
                        md: '3rem',
                        sm: '2.5rem',
                        xs: '2rem',
                    }}
                >
                    <span style={{ color: link }}>Tutred</span> in the press
                </Typography>
                <Typography
                    fontFamily={defaultStyles.mediumFont}
                    textAlign="center"
                    letterSpacing="-1.8px"
                    sx={{
                        textWrap: 'balance',
                    }}
                    fontSize={{
                        lg: '1.6rem',
                        xs: '1.1rem',
                    }}
                >
                    Tutred welcomes media inquiries and brand partnerships!{' '}
                    <br />
                    Reach out to our media team for all inquiries.
                </Typography>
            </Box>
        </Box>
    )
}

export default PressHero
