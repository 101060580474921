import { Box, Grid2 } from '@mui/material'
import React from 'react'
import OfferingCountItem from './OfferingCountItem'
import OfferingListItem from './OfferlingListItem'

const Offerings: React.FC = () => {
    return (
        <Box
            sx={{
                maxWidth: 1280,
                margin: 'auto',
            }}
        >
            <Grid2
                container
                spacing={{ xs: 4, sm: 2 }}
                sx={{
                    px: {
                        sm: 4,
                        md: 8,
                    },
                }}
            >
                <OfferingCountItem
                    icon="atom"
                    count="50"
                    label="Core subjects"
                />
                <OfferingCountItem
                    icon="microscope"
                    count="500"
                    label="Specialties"
                />
                <OfferingCountItem
                    icon="translate"
                    count="30"
                    label="Languages"
                />
                <OfferingCountItem
                    icon="paper"
                    count="40"
                    label="Exam prep topics"
                />
            </Grid2>
            <Grid2
                container
                sx={{
                    p: { xs: 2, sm: 4, md: 6 },
                }}
                spacing={{ lg: 8, md: 6, sm: 4 }}
            >
                <Grid2
                    size={{ xs: 12, sm: 8 }}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: { xs: 2, md: 4 },
                    }}
                >
                    <OfferingListItem text="Choose from a variety of core subjects to learn like Mathematics, English, Chemistry, Biology. and more." />
                    <OfferingListItem text="Explore over 500 specialties in core subjects, offering focus areas like Organic Chemistry or Microbiology." />
                    <OfferingListItem text="Pick the language you want to learn in. You can learn  Math in English, Spanish, French, plus more." />
                    <OfferingListItem text="Find specialized tutors to help you prepare for standardized tests like the SAT, LSAT,  MCAT, and more." />
                </Grid2>
                <Grid2
                    size={4}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            maxWidth: '100%',
                            my: {
                                xs: 2,
                                sm: 0,
                            },
                        }}
                        src={require('@assets/images/landing-page/offerings.svg')}
                    />
                </Grid2>
            </Grid2>
        </Box>
    )
}

export default Offerings
