import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useStyles } from '@utils/styles'
import React from 'react'

const MoreQuestionsFooter: React.FC = () => {
    const defaultStyles = useStyles()
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    return (
        <Box
            sx={{
                pt: { lg: 10, sm: 8, xs: 6 },
                pb: { lg: 8, sm: 6, xs: 4 },
                px: 2,
                maxWidth: 1280,
                margin: 'auto',
                overflow: 'hidden',
            }}
        >
            <Typography
                variant={isMd ? 'h4' : 'h6'}
                fontFamily={defaultStyles.titleFont}
                letterSpacing={isMd ? '-1.8px' : '-1.08px'}
                textAlign="center"
            >
                Still have more questions?
            </Typography>
            <Typography
                variant={isMd ? 'h5' : 'body1'}
                letterSpacing={isMd ? '-1.8px' : '-1.08px'}
                textAlign="center"
            >
                Read our articles from the{' '}
                <a
                    style={{
                        textDecoration: 'underline',
                        fontFamily: 'Poppins_500Medium',
                        cursor: 'pointer',
                        color: '#000',
                    }}
                    href="/help"
                >
                    help center to learn more.
                </a>
            </Typography>
        </Box>
    )
}

export default MoreQuestionsFooter
