import { Box, Container, Grid2, Link, Typography } from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import React from 'react'
import FooterTitle from './FooterTitle'
import FooterLink from './FooterLink'

const Footer: React.FC = () => {
    const defaultStyles = useStyles()
    const { primaryLight } = useColorsContext()

    return (
        <Box
            sx={{
                backgroundColor: primaryLight,
            }}
        >
            <Container
                maxWidth="xl"
                sx={{
                    padding: { md: 6, sm: 4, xs: 2 },
                }}
            >
                <Grid2 container>
                    <Grid2 size={{ xs: 12, sm: 8 }}>
                        <Grid2 container spacing={4}>
                            <Grid2 display="flex" flexDirection="column">
                                <FooterTitle title="Tutred" />
                                <FooterLink href="/about">About</FooterLink>
                                <FooterLink href="/privacy-policy">
                                    Policies
                                </FooterLink>
                                <FooterLink href="/careers">Careers</FooterLink>
                                <FooterLink href="/press">Press</FooterLink>
                            </Grid2>
                            <Grid2 display="flex" flexDirection="column">
                                <FooterTitle title="Explore" />
                                <FooterLink href="/tutors">
                                    Find a Tutor
                                </FooterLink>
                                <FooterLink href="/help">Get help</FooterLink>
                            </Grid2>
                            <Grid2 display="flex" flexDirection="column">
                                <FooterTitle title="Tutors" />
                                <FooterLink href="/become-a-tutor">
                                    Become a tutor
                                </FooterLink>
                                <FooterLink href="/elite-tutor-program">
                                    Elite Tutor program
                                </FooterLink>
                            </Grid2>
                            <Grid2 display="flex" flexDirection="column">
                                <Link
                                    fontFamily={defaultStyles.boldFont}
                                    fontSize="1.2rem"
                                    color="#000"
                                    underline="hover"
                                    letterSpacing="-1.08px"
                                    href="/help"
                                >
                                    Help Center
                                </Link>
                            </Grid2>

                            <Grid2 size={12}>
                                <Grid2 container spacing={3}>
                                    <Typography
                                        sx={{
                                            fontFamily:
                                                defaultStyles.mediumFont,
                                            fontSize: '.9rem',
                                            color: '#000',
                                            letterSpacing: '-1.08px',
                                        }}
                                    >
                                        © Tutred {new Date().getFullYear()}
                                    </Typography>
                                    <FooterLink>Terms</FooterLink>
                                    <FooterLink>Site map</FooterLink>
                                    <FooterLink href="privacy-policy">
                                        Privacy
                                    </FooterLink>
                                    <FooterLink>Cookie preferences</FooterLink>
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 4 }}>
                        <Grid2
                            container
                            sx={{
                                height: '100%',
                                pt: { xs: 2, sm: 0 },
                                pb: { xs: 1, sm: 0 },
                            }}
                            alignItems={{ xs: 'center', sm: 'flex-end' }}
                            direction={{ xs: 'row-reverse', sm: 'column' }}
                            justifyContent="space-between"
                        >
                            <Grid2
                                size={{ xs: 6, sm: 12 }}
                                display="flex"
                                justifyContent="flex-end"
                                gap={2}
                            >
                                <Link href="https://x.com/TutredTeam">
                                    <Box
                                        component="img"
                                        src={require('@assets/images/social-icons/x-twitter.svg')}
                                        sx={{
                                            width: 22,
                                            height: 22,
                                            objectFit: 'contain',
                                        }}
                                    />
                                </Link>
                                <Link href="https://www.instagram.com/TutredInc">
                                    <Box
                                        component="img"
                                        src={require('@assets/images/social-icons/instagram.svg')}
                                        sx={{
                                            width: 22,
                                            height: 22,
                                            objectFit: 'contain',
                                        }}
                                    />
                                </Link>
                                <Link href="https://ca.linkedin.com/company/tutred">
                                    <Box
                                        component="img"
                                        src={require('@assets/images/social-icons/linkedin.svg')}
                                        sx={{
                                            width: 22,
                                            height: 22,
                                            objectFit: 'contain',
                                        }}
                                    />
                                </Link>
                                <Link href="https://www.reddit.com/user/TutredTeam">
                                    <Box
                                        component="img"
                                        src={require('@assets/images/social-icons/reddit.svg')}
                                        sx={{
                                            width: 22,
                                            height: 22,
                                            objectFit: 'contain',
                                        }}
                                    />
                                </Link>
                            </Grid2>
                            <Grid2
                                size={{ xs: 6, sm: 12 }}
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent={{
                                    xs: 'flex-start',
                                    sm: 'flex-end',
                                }}
                            >
                                <Box
                                    component="img"
                                    src={require('@assets/images/footer/language.svg')}
                                    sx={{
                                        width: 18,
                                        height: 18,
                                        objectFit: 'contain',
                                    }}
                                />
                                <FooterTitle
                                    fontSize="1rem"
                                    title="English"
                                    sx={{
                                        ml: 0.25,
                                    }}
                                />
                                <Box
                                    component="img"
                                    src={require('@assets/images/footer/currency.svg')}
                                    sx={{
                                        width: 18,
                                        height: 18,
                                        objectFit: 'contain',
                                        ml: 1.5,
                                    }}
                                />
                                <FooterTitle
                                    fontSize="1rem"
                                    title="CAD"
                                    sx={{
                                        ml: 0.25,
                                    }}
                                />
                            </Grid2>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Container>
        </Box>
    )
}

export default Footer
