import React, { useEffect } from 'react'
import { Box, useTheme } from '@mui/material'
import { useColorsContext } from '@utils/styles'
import Discover from './Sections/Discover'
import FindTutors from './Sections/FindTutors'
import SearchTutors from './Sections/SearchTutors'
import Opportunities from './Sections/Opportunities'
import { useDialog } from '@contexts/dialogContext'
import LandingPageHero from './Sections/LandingPageHero'
import Offerings from './Sections/Offerings'
import { Footer } from '@components/Footer'

const LandingPage = ({ route }) => {
    useColorsContext()

    const theme = useTheme()
    const { openSignupDialog, openLoginDialog, setShowLoginModal } = useDialog()

    useEffect(() => {
        if (route.path === '/become-a-tutor') {
            openSignupDialog('tutor')
        }
        if (route.path === '/onboarding') {
            openLoginDialog('Login to complete your profile')
        }
        if (!route.path) {
            setShowLoginModal(false)
        }
    }, [route.path])

    return (
        <Box
            sx={{
                flex: 1,
                width: '100%',
                overflowY: 'auto',
                backgroundColor: '#FCFCFC',
            }}
        >
            <LandingPageHero />
            <Discover />
            <FindTutors />
            <Offerings />
            <SearchTutors />
            <Opportunities />
            <Footer />
        </Box>
    )
}

export default LandingPage
