import React from 'react'
import {
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    useTheme,
    useMediaQuery,
    Grid2,
} from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import {
    NavigationProp,
    ParamListBase,
    useNavigation,
} from '@react-navigation/native'
import { useUserContext } from '@contexts/userContext'
import { useDialog } from '@contexts/dialogContext'
import { NativeEventEmitter } from 'react-native'

const BecomeAnEliteTutorHero = () => {
    const { link, primaryLight } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const navigation: NavigationProp<ParamListBase> = useNavigation()
    const { user } = useUserContext()
    const { openLoginDialog } = useDialog()
    const eventEmitter = new NativeEventEmitter()

    const handleNavigateToPerformance = () => {
        if (user) {
            navigation.navigate('Settings', {
                screen: 'Performance',
            })
        } else {
            openLoginDialog('Login to view your performance')
            eventEmitter.addListener(
                'login-success',
                ({ user, extendedUser }) => {
                    if (!extendedUser.isTutor) {
                        navigation.navigate('Sessions')
                    } else {
                        navigation.navigate('Settings', {
                            screen: 'Performance',
                        })
                    }
                }
            )
        }
    }

    return (
        <Grid2
            container
            sx={{
                p: { md: 6, sm: 4, xs: 2 },
                maxWidth: 1280,
                margin: 'auto',
                overflow: 'hidden',
            }}
            spacing={4}
            columns={16}
        >
            <Grid2
                size={{ xs: 16, sm: 6 }}
                sx={{
                    backgroundColor: primaryLight,
                    borderRadius: 4,
                    textAlign: 'left',
                    display: 'flex',
                    p: { md: 4, xs: 2 },
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Box>
                    <Typography
                        fontFamily={defaultStyles.titleFont}
                        // variant={isLg ? 'h2' : isMd ? 'h3' : 'h4'}
                        letterSpacing="-2.52px"
                        textAlign="left"
                        width="100%"
                        fontSize={{
                            lg: '3.8rem',
                            md: '3rem',
                            sm: '2.5rem',
                            xs: '3.5rem',
                        }}
                        lineHeight="1.2"
                        sx={{
                            mb: { md: 1 },
                        }}
                    >
                        Become an <br />
                        <span style={{ color: link }}>Elite Tutor</span>
                    </Typography>
                    <Typography
                        fontFamily={defaultStyles.mediumFont}
                        fontSize={{
                            lg: '1.2rem',
                            sm: '.8rem',
                            xs: '1.2rem',
                        }}
                        letterSpacing="-1.08px"
                        textAlign="left"
                        width="100%"
                    >
                        The Elite Tutor program rewards the most reliable and
                        highest-rated tutors on the platform
                    </Typography>
                </Box>

                <Box
                    component="img"
                    sx={{
                        maxHeight: { xs: 140, md: 220 },
                        mt: { md: 6, xs: 4 },
                        mb: 2,
                    }}
                    src={require('@assets/images/become-an-elite-tutor/trophy.png')}
                />
            </Grid2>
            <Grid2
                size={{ xs: 16, sm: 10 }}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box
                    component="img"
                    sx={{ maxHeight: { xs: 290, sm: 225, md: 420 } }}
                    src={require('@assets/images/become-an-elite-tutor/hero.png')}
                />

                <Button
                    sx={{
                        marginLeft: 2,
                        letterSpacing: '-1.08px',
                        fontSize: '1rem',
                        paddingX: 4,
                        mt: 8,
                    }}
                    size={isMd ? 'medium' : 'small'}
                    variant="contained"
                    onClick={handleNavigateToPerformance}
                    disableElevation
                >
                    Check your performance
                </Button>
            </Grid2>
        </Grid2>
    )
}

export default BecomeAnEliteTutorHero
