import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import PrivacyPolicy from '@screens/LegalMatters/PrivacyPolicy'
import TermsOfService from '@screens/LegalMatters/TermsOfService'

const Stack = createNativeStackNavigator()

const LegalMattersStack = () => {
    return (
        <Stack.Navigator
            screenOptions={{
                headerShown: false,
            }}
        >
            <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicy} />
            <Stack.Screen name="TermsOfService" component={TermsOfService} />
        </Stack.Navigator>
    )
}

export default LegalMattersStack
