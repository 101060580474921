import { Box, Grid2, Typography } from '@mui/material'
import { useStyles } from '@utils/styles'

const AchievingEliteTutorItem = ({ icon, text, title }) => {
    const defaultStyles = useStyles()
    return (
        <Grid2 size={{ sm: 6, xs: 12 }}>
            <Box
                component="img"
                sx={{
                    objectFit: 'contain',
                    height: { xs: 50, lg: 50 },
                    mb: { lg: 4, xs: 2 },
                }}
                src={require(
                    `@assets/images/become-an-elite-tutor/${icon}.png`
                )}
            />
            <Typography
                fontFamily={defaultStyles.titleFont}
                letterSpacing="-1.8px"
                fontSize={{
                    lg: '1.6rem',
                    md: '1.4rem',
                    sm: '1.6rem',
                    xs: '1.3rem',
                }}
            >
                {title}
            </Typography>
            <Typography
                fontFamily={defaultStyles.mediumFont}
                letterSpacing="-1.8px"
                fontSize={{
                    lg: '1.4rem',
                    md: '1.4rem',
                    sm: '1.6rem',
                    xs: '1.3rem',
                }}
            >
                {text}
            </Typography>
        </Grid2>
    )
}

export default AchievingEliteTutorItem
