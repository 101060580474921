import React from 'react'
import { Box } from '@mui/material'

const FindTutorImage = ({ icon }) => {
    if (!icon)
        return (
            <Box
                sx={{
                    width: { xs: 25, sm: 40, md: 60 },
                    height: { xs: 25, sm: 40, md: 60 },
                }}
            ></Box>
        )
    return (
        <Box
            sx={{
                width: { xs: 25, sm: 40, md: 60 },
                height: { xs: 25, sm: 40, md: 60 },
                backgroundColor: '#FFF',
                maskImage: `url(${require(`@assets/images/subjects/${icon}.svg`)})`,
                WebkitMaskImage: `url(${require(`@assets/images/subjects/${icon}.svg`)})`,
                maskSize: 'contain',
                WebkitMaskSize: 'contain',
                maskRepeat: 'no-repeat',
                maskPosition: 'center',
            }}
        />
    )
}

export default FindTutorImage
