import { Link } from '@react-navigation/native'
import { Typography } from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'

interface LegalMattersLinkProps {
    to: string
    text: string
    screen: string
    isActive: boolean
}

const LegalMattersLink = ({ to, text, isActive }: LegalMattersLinkProps) => {
    const defaultStyles = useStyles()

    return (
        <Link to={to}>
            <Typography
                variant="h6"
                textAlign="center"
                fontFamily={defaultStyles.titleFont}
                letterSpacing="-1.08px"
                color={isActive ? '#000' : '#FFF'}
            >
                {text}
            </Typography>
        </Link>
    )
}

export default LegalMattersLink
