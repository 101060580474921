// Package imports
import { MaterialIcons } from "@expo/vector-icons";
import React, { useEffect, useState } from "react";
import { FlatList, Platform, StyleSheet, Text, useWindowDimensions, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useMediaQuery } from "react-responsive";

// File imports
import { useUserContext } from "../../../contexts/userContext";
import { useColorsContext, useStyles } from "../../../utils/styles";

import { Chat } from "../../../components/Chat/Chat";
import { ChatListCard } from "../../../components/Chat/ChatListCard";

export const Chats = ({ navigation, route }) => {
  // Firebase
  const [headerHeight, setHeaderHeight] = useState(133);
  const { chats, user } = useUserContext();

  // Responsive Breakpoints
  const isMd = useMediaQuery({ query: "(min-width: 768px)" });

  // Appearance
  const {
    primaryVariant,
    background,
    text,
    textVariant,
    border,
  } = useColorsContext();
  const defaultStyles = useStyles();

  // Styles
  const styles = StyleSheet.create({
    container: {
      backgroundColor: background,
      flex: 1,
    },
    maxWidth: {
      flex: 1,
      display: "flex",
      flexDirection: Platform.OS === "web" ? "row" : null,
      width: "100%",
      margin: Platform.OS === "web" ? "auto" : null,
    },
    title: {
      color: text,
      fontSize: 36,
      fontFamily: defaultStyles.titleFont,
      marginBottom: 15,
    },
    indicator: {
      marginTop: 15,
      color: text,
      fontSize: 22,
      fontFamily: defaultStyles.boldFont,
      textAlign: "center",
    },
    indicatorMessage: {
      color: textVariant,
      fontSize: 16,
      fontFamily: defaultStyles.regularFont,
    },
  });

  const [openChat, setOpenChat] = useState(null);

  useEffect(() => {
    if (route.params?.chat) {
      // Find chat by route params openChat
      chats.forEach((chat) => {
        if (chat.id === route.params?.chat) {
          setOpenChat({
            chat: chat,
            hoursSinceOnline: route.params?.lo,
          });
        }
      });
    }
  }, [route.params, chats]);

  const { height } = useWindowDimensions();


  return (
    <SafeAreaView style={styles.container}>
      {isMd || !openChat ? (
        <View onLayout={(({ nativeEvent }) => {
          setHeaderHeight(nativeEvent.layout.height);
        })}>
        </View>
      ) : null}
      <View style={styles.maxWidth}>
        {/* Main section  */}
        <View
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            padding: 15,
            height: height - headerHeight,
          }}
        >
          <View
            style={{
              flex: 2,
              borderRight: isMd ? "1px solid " + border : null,
              paddingRight: isMd ? 15 : null,
            }}
          >
            <Text style={styles.title}>Chats</Text>

            <FlatList
              data={chats}
              keyExtractor={(item) => item.id}
              renderItem={({ item, index }) => (
                <ChatListCard
                  key={`chat-${index}`}
                  chat={item}
                  openChat={openChat}
                  navigation={navigation}
                  setOpenChat={setOpenChat}
                />
              )}
            />
          </View>

          {isMd && (
            <View
              style={{
                flex: 5,
                paddingLeft: 15,
                justifyContent: !openChat ? "center" : "",
                alignItems: !openChat ? "center" : "",
              }}
            >
              {openChat ? (
                <Chat
                  openChat={openChat}
                  setOpenChat={setOpenChat}
                  navigation={navigation}
                />
              ) : (
                <>
                  <MaterialIcons
                    name="chat"
                    size={100}
                    color={primaryVariant}
                  />
                  <Text style={styles.indicator}>Your Chats</Text>
                  {user ? (
                    <Text style={styles.indicatorMessage}>
                      {chats.length > 0
                        ? "Select a chat to view messages"
                        : "You have no chats yet"}
                    </Text>
                  ) : (
                    <Text style={styles.indicatorMessage}>
                      Sign in to view your chats
                    </Text>
                  )}
                </>
              )}
            </View>
          )}
        </View>
      </View>
    </SafeAreaView>
  );
};
