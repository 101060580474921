import React from 'react'
import { SxProps, Typography } from '@mui/material'
import { useStyles } from '@utils/styles'

interface FooterTitleProps {
    title: string
    fontSize?: string
    sx?: SxProps
}
const FooterTitle: React.FC<FooterTitleProps> = ({
    title,
    fontSize = '1.2rem',
    sx = {
        mb: 2,
    },
}) => {
    const defaultStyles = useStyles()

    return (
        <Typography
            variant="h2"
            fontFamily={defaultStyles.boldFont}
            fontSize={fontSize}
            letterSpacing="-1.08px"
            sx={{
                ...sx,
            }}
        >
            {title}
        </Typography>
    )
}

export default FooterTitle
