import React from 'react'
import {
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    useTheme,
    useMediaQuery,
    Grid2,
} from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import PerkItem from './PerkItem'

const PerksAndBenefits = () => {
    const { primary, primaryLight } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))

    return (
        <Box sx={{ backgroundColor: primary }}>
            <Grid2
                container
                direction={{ xs: 'column-reverse', md: 'row' }}
                sx={{
                    p: { lg: 6, sm: 4, xs: 2 },
                    backgroundColor: 'inherit',
                    maxWidth: 1280,
                    margin: '0 auto',
                    overflow: 'hidden',
                }}
                spacing={{ md: 6, xs: 0 }}
            >
                {!isSm && (
                    <Box
                        component="img"
                        sx={{
                            maxHeight: 180,
                            objectFit: 'contain',
                        }}
                        src={require('@assets/images/become-an-elite-tutor/present.png')}
                    />
                )}

                <Grid2
                    size={{ xs: 12, md: 6 }}
                    sx={{
                        textAlign: 'left',
                        display: 'flex',
                        my: 2,
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <PerkItem icon="visibility" text="Get more visibility" />
                    <PerkItem
                        icon="money"
                        text="Reduce your commission fee to 8%"
                    />
                    <PerkItem
                        icon="badge"
                        text="Exclusive badge on your profile"
                    />
                    <PerkItem
                        icon="new-features"
                        text="Early access to new features"
                    />
                </Grid2>
                <Grid2
                    size={{ xs: 12, md: 6 }}
                    sx={{
                        display: 'flex',
                        flexDirection: isMd ? 'column' : 'row',
                        alignItems: isMd ? 'center' : 'flex-start',
                        justifyContent: 'center',
                    }}
                >
                    <Box>
                        <Typography
                            fontFamily="Poppins_600SemiBold"
                            textAlign="left"
                            width="100%"
                            letterSpacing="-1.8px"
                            fontSize={{
                                lg: '3.2rem',
                                md: '2.8rem',
                                sm: '3rem',
                                xs: '2.4rem',
                            }}
                            color="#FFF"
                        >
                            The perks & benefits
                        </Typography>
                        <Typography
                            fontFamily="Poppins_600SemiBold"
                            color="#000"
                            width="100%"
                            textAlign="left"
                            sx={{
                                px: { lg: 1.5, sm: 1 },
                            }}
                            letterSpacing="-2.52px"
                            fontSize={{
                                lg: '1.7rem',
                                md: '1.5rem',
                                sm: '1.5rem',
                                xs: '1.4rem',
                            }}
                        >
                            Elite Tutors enjoy exclusive perks like higher
                            earnings, better visibility and an exclusive profile
                            badge.
                        </Typography>
                    </Box>
                    {isSm && (
                        <Box
                            component="img"
                            sx={{
                                maxHeight: { sm: 225, md: 375, lg: 425 },
                            }}
                            src={require('@assets/images/become-an-elite-tutor/present.png')}
                        />
                    )}
                </Grid2>
            </Grid2>
        </Box>
    )
}

export default PerksAndBenefits
