import { useColorsContext, useStyles } from '@utils/styles'
import {
    useTheme,
    useMediaQuery,
    Box,
    Grid2,
    Typography,
    Button,
} from '@mui/material'
import React from 'react'
import {
    NavigationProp,
    ParamListBase,
    useNavigation,
} from '@react-navigation/native'

const TeachDiverse: React.FC = () => {
    const { primary, link, primaryLight } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))
    const isMd = useMediaQuery(theme.breakpoints.up('md'))

    const navigation: NavigationProp<ParamListBase> = useNavigation()

    const subjectCategories = [
        {
            title: 'Sciences',
            icons: ['Mathematics', 'Physics', 'Biology', 'Chemistry'],
        },
        {
            title: 'Arts',
            icons: ['Art', 'Dance', 'Theater', 'Music'],
        },
        {
            title: 'Social Sciences',
            icons: ['Law', 'Political-Science', 'Anthropology', 'Geography'],
        },
        {
            title: 'Humanities',
            icons: ['Philosophy', 'Language', 'English', 'History'],
        },
    ]
    return (
        <Box
            sx={{
                p: { md: 6, xs: 2 },
                backgroundColor: primaryLight,
            }}
        >
            <Box
                sx={{
                    maxWidth: 1280,
                    margin: 'auto',
                    overflow: 'hidden',
                }}
            >
                <Grid2
                    container
                    direction={{ sm: 'row', xs: 'column-reverse' }}
                >
                    <Grid2 size={{ xs: 12, sm: 5 }}>
                        <Grid2
                            container
                            rowGap={4}
                            sx={{
                                backgroundColor: '#FFF',
                                borderRadius: 6,
                                p: 3,
                            }}
                        >
                            {subjectCategories.map((category) => (
                                <Grid2
                                    size={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Grid2
                                        container
                                        rowGap={2}
                                        // direction="row"
                                        // alignItems="center"
                                        // justifyContent="center"
                                    >
                                        {category.icons.map((icon) => (
                                            <Grid2
                                                size={6}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        height: {
                                                            lg: 42,
                                                            md: 32,
                                                            xs: 24,
                                                        },
                                                        width: {
                                                            lg: 42,
                                                            md: 32,
                                                            xs: 24,
                                                        },
                                                        backgroundColor:
                                                            primary,
                                                        maskImage: `url(${require(`@assets/images/subjects/${icon}.svg`)})`,
                                                        WebkitMaskImage: `url(${require(`@assets/images/subjects/${icon}.svg`)})`,
                                                        maskSize: 'contain',
                                                        WebkitMaskSize:
                                                            'contain',
                                                        maskRepeat: 'no-repeat',
                                                        maskPosition: 'center',
                                                    }}
                                                />
                                            </Grid2>
                                        ))}
                                    </Grid2>
                                    <Typography
                                        variant={isMd ? 'h5' : 'body1'}
                                        fontFamily={defaultStyles.titleFont}
                                        letterSpacing="-1.8px"
                                        sx={{
                                            mt: 2,
                                        }}
                                    >
                                        {category.title}
                                    </Typography>
                                </Grid2>
                            ))}
                        </Grid2>
                    </Grid2>
                    <Grid2
                        size={{ xs: 12, sm: 7 }}
                        sx={{ pl: { xs: 1, sm: 4 } }}
                    >
                        <Typography
                            variant={isMd ? 'h3' : 'h4'}
                            fontFamily={defaultStyles.titleFont}
                            letterSpacing="-1.08px"
                            lineHeight={1.5}
                        >
                            Teach diverse subjects, specialties, and exam prep
                            tests
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                pb: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    mt: 2,
                                }}
                            >
                                <Typography
                                    variant={isMd ? 'h3' : 'h4'}
                                    fontFamily={defaultStyles.boldFont}
                                    letterSpacing="-1.8px"
                                >
                                    50+
                                </Typography>
                                <Typography
                                    variant={isMd ? 'h6' : 'body1'}
                                    fontFamily={defaultStyles.titleFont}
                                    letterSpacing="-1.8px"
                                >
                                    Subjects
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    mt: 2,
                                }}
                            >
                                <Typography
                                    variant={isMd ? 'h3' : 'h4'}
                                    fontFamily={defaultStyles.boldFont}
                                    letterSpacing="-1.8px"
                                >
                                    500+
                                </Typography>
                                <Typography
                                    variant={isMd ? 'h6' : 'body1'}
                                    fontFamily={defaultStyles.titleFont}
                                    letterSpacing="-1.8px"
                                >
                                    Specialties
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    mt: 2,
                                    maxWidth: 200,
                                }}
                            >
                                <Typography
                                    variant={isMd ? 'h3' : 'h4'}
                                    fontFamily={defaultStyles.boldFont}
                                    letterSpacing="-1.8px"
                                >
                                    40+
                                </Typography>
                                <Typography
                                    variant={isMd ? 'h6' : 'body1'}
                                    fontFamily={defaultStyles.titleFont}
                                    letterSpacing="-1.8px"
                                >
                                    Exam prep topics <br /> (LSAT, MCAT, etc.)
                                </Typography>
                            </Box>
                        </Box>
                    </Grid2>
                </Grid2>
                <Grid2 container sx={{ pb: 2, pt: 6 }}>
                    <Grid2 size={{ sm: 8, xs: 10 }}>
                        <Typography
                            fontFamily={defaultStyles.titleFont}
                            variant={isMd ? 'h3' : 'h4'}
                            letterSpacing="-1.8px"
                            lineHeight={1.5}
                        >
                            Teach on-demand <br /> lessons with{' '}
                            <span style={{ color: link }}>Instabook</span>
                        </Typography>
                        {isSm && (
                            <Button
                                variant="contained"
                                sx={{
                                    fontFamily: defaultStyles.boldFont,
                                    fontSize: {
                                        xs: '1rem',
                                        sm: '1rem',
                                        md: '1.25rem',
                                    },
                                    mt: 2,
                                    px: { xs: 4, sm: 8 },
                                    height: { xs: 48, sm: 50, md: 52 },
                                    backgroundColor: '#FFF',
                                    border: `3px solid black`,
                                    color: '#000',
                                    borderRadius: '12px',
                                    width: { xs: '100%', sm: 'auto' },
                                }}
                                onClick={() => {
                                    navigation.navigate('Help', {
                                        screen: 'HelpArticle',
                                        params: {
                                            slug: 'what-is-instabook',
                                        },
                                    })
                                }}
                            >
                                Learn more
                            </Button>
                        )}
                    </Grid2>

                    <Grid2
                        size={{ xs: 2, sm: 4 }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                            pr: 3,
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                maxHeight: { md: 226, sm: 160, xs: 100 },
                            }}
                            src={require('@assets/images/become-a-tutor/on-demand.png')}
                        />
                    </Grid2>
                    {!isSm && (
                        <Button
                            variant="contained"
                            sx={{
                                fontFamily: defaultStyles.boldFont,
                                fontSize: {
                                    xs: '1rem',
                                    sm: '1rem',
                                    md: '1.25rem',
                                },
                                mt: 2,
                                px: { xs: 4, sm: 8 },
                                height: { xs: 48, sm: 50, md: 52 },
                                backgroundColor: '#FFF',
                                border: `3px solid black`,
                                color: '#000',
                                borderRadius: '12px',
                                width: { xs: '100%', sm: 'auto' },
                            }}
                            // onClick={() => openSignupDialog('tutor')}
                        >
                            Learn more
                        </Button>
                    )}
                </Grid2>
            </Box>
        </Box>
    )
}

export default TeachDiverse
