import '@rc-component/color-picker/assets/index.css'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'
import TextStyle from '@tiptap/extension-text-style'
import TextAlign from '@tiptap/extension-text-align'
import OrderedList from '@tiptap/extension-ordered-list'
import BulletList from '@tiptap/extension-bullet-list'
import ListItem from '@tiptap/extension-list-item'
import FontFamily from '@tiptap/extension-font-family'
import Color from '@tiptap/extension-color'
import HardBreak from '@tiptap/extension-hard-break'
import ImageResize from 'tiptap-extension-resize-image'
import { useState, useEffect } from 'react'
import {
    Box,
    Paper,
    Tabs,
    Tab,
    Select,
    MenuItem,
    IconButton,
    Divider,
    TextField,
    styled,
} from '@mui/material'
import {
    FormatBold,
    FormatItalic,
    FormatUnderlined,
    Link as LinkIcon,
    FormatListBulleted,
    FormatListNumbered,
    FormatAlignLeft,
    FormatAlignCenter,
    FormatAlignRight,
    FormatQuote,
    Image as ImageIcon,
    Code,
    Description,
    Visibility,
    FormatLineSpacing,
    InsertPageBreak,
} from '@mui/icons-material'
import HelpArticlePreview from './HelpArticlePreview'
import ColorPicker from '@rc-component/color-picker'

const FONT_FAMILIES = [
    { name: 'Regular', value: 'Poppins_400Regular' },
    { name: 'Light', value: 'Poppins_300Light' },
    { name: 'Medium', value: 'Poppins_500Medium' },
    { name: 'SemiBold', value: 'Poppins_600SemiBold' },
    { name: 'Bold', value: 'Poppins_700Bold' },
]

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    '&.active': {
        backgroundColor: theme.palette.action.selected,
    },
}))

const MenuBar = ({ editor }: { editor: any }) => {
    if (!editor) {
        return null
    }

    const addLink = () => {
        const url = window.prompt('Enter URL')
        if (url) {
            editor.chain().focus().setLink({ href: url }).run()
        }
    }

    const addImage = () => {
        const url = window.prompt('Enter image URL')
        if (url) {
            editor.chain().focus().setImage({ src: url, alt: 'Image' }).run()
        }
    }

    return (
        <Box
            sx={{
                p: 1,
                display: 'flex',
                gap: 1,
                flexWrap: 'wrap',
                alignItems: 'center',
            }}
        >
            <Select
                size="small"
                value={
                    editor.isActive('paragraph')
                        ? 'paragraph'
                        : editor.isActive('heading', { level: 1 })
                          ? 'h1'
                          : editor.isActive('heading', { level: 2 })
                            ? 'h2'
                            : editor.isActive('heading', { level: 3 })
                              ? 'h3'
                              : editor.isActive('heading', { level: 4 })
                                ? 'h4'
                                : editor.isActive('heading', { level: 5 })
                                  ? 'h5'
                                  : editor.isActive('heading', { level: 6 })
                                    ? 'h6'
                                    : 'paragraph'
                }
                onChange={(e) => {
                    const value = e.target.value
                    if (value === 'paragraph') {
                        editor.chain().focus().setParagraph().run()
                    } else {
                        const level = parseInt(value.replace('h', ''))
                        editor.chain().focus().toggleHeading({ level }).run()
                    }
                }}
                sx={{ minWidth: 120 }}
            >
                <MenuItem value="paragraph">Paragraph</MenuItem>
                <MenuItem value="h1">Heading 1</MenuItem>
                <MenuItem value="h2">Heading 2</MenuItem>
                <MenuItem value="h3">Heading 3</MenuItem>
                <MenuItem value="h4">Heading 4</MenuItem>
                <MenuItem value="h5">Heading 5</MenuItem>
                <MenuItem value="h6">Heading 6</MenuItem>
            </Select>

            <Select
                size="small"
                value={
                    editor.getAttributes('textStyle').fontFamily ||
                    'Poppins_400Regular'
                }
                onChange={(e) =>
                    editor.chain().focus().setFontFamily(e.target.value).run()
                }
                sx={{ minWidth: 120 }}
            >
                {FONT_FAMILIES.map((font) => (
                    <MenuItem key={font.value} value={font.value}>
                        {font.name}
                    </MenuItem>
                ))}
            </Select>

            <input
                type="color"
                onInput={(event) =>
                    editor.chain().focus().setColor(event.target.value).run()
                }
                value={editor.getAttributes('textStyle').color}
                data-testid="setColor"
            />

            <Divider orientation="vertical" flexItem />

            <StyledIconButton
                onClick={() => editor.chain().focus().toggleBold().run()}
                className={editor.isActive('bold') ? 'active' : ''}
                size="small"
            >
                <FormatBold />
            </StyledIconButton>
            <StyledIconButton
                onClick={() => editor.chain().focus().toggleItalic().run()}
                className={editor.isActive('italic') ? 'active' : ''}
                size="small"
            >
                <FormatItalic />
            </StyledIconButton>
            <StyledIconButton
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                className={editor.isActive('underline') ? 'active' : ''}
                size="small"
            >
                <FormatUnderlined />
            </StyledIconButton>
            <StyledIconButton
                onClick={addLink}
                className={editor.isActive('link') ? 'active' : ''}
                size="small"
            >
                <LinkIcon />
            </StyledIconButton>

            <Divider orientation="vertical" flexItem />

            <StyledIconButton
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={editor.isActive('bulletList') ? 'active' : ''}
                size="small"
            >
                <FormatListBulleted />
            </StyledIconButton>
            <StyledIconButton
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={editor.isActive('orderedList') ? 'active' : ''}
                size="small"
            >
                <FormatListNumbered />
            </StyledIconButton>

            <Divider orientation="vertical" flexItem />

            <StyledIconButton
                onClick={() =>
                    editor.chain().focus().setTextAlign('left').run()
                }
                className={
                    editor.isActive({ textAlign: 'left' }) ? 'active' : ''
                }
                size="small"
            >
                <FormatAlignLeft />
            </StyledIconButton>
            <StyledIconButton
                onClick={() =>
                    editor.chain().focus().setTextAlign('center').run()
                }
                className={
                    editor.isActive({ textAlign: 'center' }) ? 'active' : ''
                }
                size="small"
            >
                <FormatAlignCenter />
            </StyledIconButton>
            <StyledIconButton
                onClick={() =>
                    editor.chain().focus().setTextAlign('right').run()
                }
                className={
                    editor.isActive({ textAlign: 'right' }) ? 'active' : ''
                }
                size="small"
            >
                <FormatAlignRight />
            </StyledIconButton>

            <Divider orientation="vertical" flexItem />

            <StyledIconButton
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                className={editor.isActive('blockquote') ? 'active' : ''}
                size="small"
            >
                <FormatQuote />
            </StyledIconButton>
            <StyledIconButton onClick={addImage} size="small">
                <ImageIcon />
            </StyledIconButton>
            <StyledIconButton
                onClick={() => editor.chain().focus().setHardBreak().run()}
                size="small"
            >
                <InsertPageBreak />
            </StyledIconButton>
        </Box>
    )
}

type Tab = 'editor' | 'source' | 'preview'

export default function Editor() {
    const [activeTab, setActiveTab] = useState<Tab>('editor')
    const [sourceContent, setSourceContent] = useState('')
    const editor = useEditor({
        extensions: [
            StarterKit.configure({
                heading: {
                    levels: [1, 2, 3, 4, 5, 6],
                },
            }),
            Underline,
            Link.configure({
                openOnClick: false,
                autolink: true,
            }),
            ImageResize,
            TextStyle,
            FontFamily.configure({
                types: ['textStyle'],
            }),
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }),
            OrderedList,
            BulletList,
            ListItem,
            Color,
            // ImageResize,
        ],
        content: '<p>Start writing your article...</p>',
        editorProps: {
            attributes: {
                style: 'font-size: 1rem; line-height: 1.5;',
            },
        },
        onUpdate: ({ editor }) => {
            setSourceContent(editor.getHTML())
        },
    })

    useEffect(() => {
        if (editor && sourceContent) {
            const currentContent = editor.getHTML()
            if (currentContent !== sourceContent) {
                editor.commands.setContent(sourceContent)
            }
        }
    }, [sourceContent, editor])

    return (
        <Paper
            elevation={1}
            sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={activeTab}
                    onChange={(_, value) => setActiveTab(value as Tab)}
                >
                    <Tab
                        icon={<Description sx={{ fontSize: 20 }} />}
                        iconPosition="start"
                        label="Editor"
                        value="editor"
                    />
                    <Tab
                        icon={<Code sx={{ fontSize: 20 }} />}
                        iconPosition="start"
                        label="Source"
                        value="source"
                    />
                    <Tab
                        icon={<Visibility sx={{ fontSize: 20 }} />}
                        iconPosition="start"
                        label="Preview"
                        value="preview"
                    />
                </Tabs>
            </Box>

            {activeTab === 'editor' && (
                <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <MenuBar editor={editor} />
                    </Box>
                    <Box sx={{ flex: 1, overflow: 'auto', p: 2 }}>
                        <EditorContent
                            editor={editor}
                            style={{
                                outline: 'none',
                                '&:focus-visible': {
                                    outline: 'none',
                                },
                            }}
                        />
                    </Box>
                </>
            )}
            {activeTab === 'preview' && (
                <HelpArticlePreview source={sourceContent} />
            )}

            {activeTab === 'source' && (
                <Box sx={{ flex: 1, overflow: 'auto', p: 2 }}>
                    <TextField
                        multiline
                        fullWidth
                        value={sourceContent}
                        onChange={(e) => setSourceContent(e.target.value)}
                        variant="outlined"
                        sx={{
                            textAlign: 'start',

                            '& .MuiInputBase-root': {
                                fontFamily: 'monospace',
                                fontSize: '0.875rem',
                            },
                        }}
                    />
                </Box>
            )}
        </Paper>
    )
}
