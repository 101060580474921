import React from 'react'
import {
    Card,
    CardContent,
    Typography,
    Box,
    Grid2,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import DiscoverItem from './DiscoverItem'

const Discover = () => {
    const { primaryLight, link } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const isSm = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Grid2
            container
            spacing={{ xs: 2, sm: 4, md: 6 }}
            sx={{
                padding: {
                    xs: 2,
                    sm: 4,
                    md: 6,
                },
                maxWidth: 1280,
                margin: 'auto',
                overflow: 'hidden',
                flexDirection: {
                    xs: 'column-reverse',
                    md: 'row',
                },
            }}
        >
            <Grid2
                size={{ xs: 12, md: 7 }}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                }}
            >
                <DiscoverItem
                    icon="247access.svg"
                    title="24/7 access to tutors."
                    text="Get the help you need, whenever you need it. Tutred connects you with expert tutors around the clock."
                />
                <DiscoverItem
                    icon="instabook.svg"
                    title="Use Instabook to learn right away."
                    text="No waiting, no hassle—connect with a tutor instantly using Instabook. Perfect for last-minute questions or quick learning sessions."
                />
                <DiscoverItem
                    icon="ideal-tutor.svg"
                    title="Find the ideal tutor to learn from."
                    text="Find the right tutor for you with personalized search filters like subjects, specialties, languages, credentials, and reviews."
                />
            </Grid2>

            <Grid2
                size={{ xs: 12, md: 5 }}
                sx={{
                    backgroundColor: primaryLight,
                    borderRadius: 4,
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row', md: 'column' },
                    alignItems: {
                        xs: 'center',
                        sm: 'flex-start',
                        md: 'center',
                    },
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        py: 2,
                        px: 3,
                    }}
                >
                    <Typography
                        fontFamily={defaultStyles.titleFont}
                        fontSize={{
                            xs: '2.2rem',
                            lg: '2.9rem',
                        }}
                        lineHeight={1.5}
                        letterSpacing="-1.8px"
                    >
                        Discover what{' '}
                        <span
                            style={{
                                color: link,
                                fontFamily: defaultStyles.boldFont,
                            }}
                        >
                            tutred
                        </span>{' '}
                        has to offer
                    </Typography>
                    <Typography
                        fontFamily={defaultStyles.titleFont}
                        fontSize={{
                            lg: '1.25rem',
                        }}
                        lineHeight={1.5}
                        letterSpacing="-1.8px"
                    >
                        We designed an innovative solution for students to
                        instantly match with the right tutor
                    </Typography>
                </Box>
                <Box
                    component="img"
                    sx={{
                        maxWidth: {
                            xs: '90%',
                            sm: '50%',
                            md: '90%',
                        },
                        pt: 4,
                        pr: { sm: 2, md: 0 },
                    }}
                    src={require('@assets/images/landing-page/discover.svg')}
                />
            </Grid2>
        </Grid2>
    )
}

export default Discover
