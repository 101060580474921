import { AntDesign, Feather } from '@expo/vector-icons'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { ActivityIndicator, NativeEventEmitter, StyleSheet } from 'react-native'
import { useUserContext } from '@contexts/userContext'
import { useColorsContext, useStyles } from '@utils/styles'
import ResetPasswordDialog from './ResetPasswordDialog'
import {
    Dialog,
    DialogContent,
    Typography,
    DialogTitle,
    TextField,
    useTheme,
    DialogActions,
    Button,
    useMediaQuery,
    IconButton,
    Box,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { Navigation } from '@mui/icons-material'
import { useNavigation } from '@react-navigation/native'
interface FormData {
    email: string
    password: string
}

interface Props {
    loginModal: boolean
    setLoginModal: (open: boolean) => void
    subTitle?: string
    openSignupDialog: (type: 'tutor' | 'student') => void
}

export const LoginDialog = ({
    loginModal,
    setLoginModal,
    subTitle,
    openSignupDialog,
}: Props) => {
    const {
        control,
        handleSubmit,
        setError,
        watch,
        reset,
        formState: { errors },
    } = useForm<FormData>()

    const { user, extendedUser } = useUserContext()
    const auth = getAuth()
    const [showPassword, setShowPassword] = useState(false)
    const [loginSuccess, setLoginSuccess] = useState(false)
    const [resetPasswordDialogOpen, setResetPasswordDialogOpen] =
        useState(false)
    const [signupDialogOpen, setSignupDialogOpen] = useState(false)
    const eventEmitter = new NativeEventEmitter()

    const { primary, text } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))
    const navigation = useNavigation()

    useEffect(() => {
        if (!loginModal) {
            reset()
        }
    }, [loginModal])

    useEffect(() => {
        if (user && loginSuccess && extendedUser) {
            eventEmitter.emit('login-success', { user, extendedUser })
            setLoginModal(false)
            return () => {
                eventEmitter.removeAllListeners('login-success')
            }
        }
    }, [user, loginSuccess, extendedUser])

    const { mutate: signin, isPending } = useMutation({
        mutationFn: (data: FormData) =>
            signInWithEmailAndPassword(auth, data.email, data.password),
        onSuccess: () => {
            setLoginSuccess(true)
            setLoginModal(false)
            // navigation.navigate('Sessions')
        },
        onError: ({ code }) => {
            setError('password', { message: '' })
            if (code !== 'auth/too-many-requests') {
                setError('email', { message: 'Invalid Email or Password' })
            } else if (code == 'auth/too-many-requests') {
                setError('email', { message: 'Too many requests' })
            } else {
                setError('email', { message: 'Something went wrong!' })
            }
        },
    })

    const onClose = () => {
        setLoginModal(false)
        reset()
    }

    const { email } = watch()

    return (
        <>
            <ResetPasswordDialog
                open={resetPasswordDialogOpen}
                setOpen={(open) => setResetPasswordDialogOpen(open)}
                loginEmail={email}
            />
            <Dialog
                open={loginModal}
                maxWidth="sm"
                onClose={onClose}
                scroll="body"
            >
                <DialogTitle
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 0,
                    }}
                >
                    <IconButton
                        onClick={onClose}
                        sx={{ position: 'absolute', top: 4, right: 4 }}
                    >
                        <AntDesign
                            name="close"
                            size={24}
                            color={theme.palette.grey[500]}
                        />
                    </IconButton>
                    <img
                        src={require('@assets/images/logo.svg')}
                        alt="logo"
                        height={isSm ? 50 : 40}
                        width="auto"
                    />
                    <Typography
                        variant={isSm ? 'h4' : 'h5'}
                        fontFamily="Inter_600SemiBold"
                        textAlign={'center'}
                        sx={{ mb: 1, mt: 1 }}
                    >
                        Welcome back
                    </Typography>
                    <Typography
                        variant={isSm ? 'body1' : 'body2'}
                        sx={{
                            color: theme.palette.text.secondary,
                            textAlign: 'center',
                            fontFamily: defaultStyles.titleFont,
                        }}
                    >
                        {subTitle || 'Enter your details to login'}
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ mt: 4 }}>
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: 'Email is required',
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Invalid email address',
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Email"
                                fullWidth
                                error={!!errors.email}
                                helperText={errors.email?.message}
                                margin="normal"
                                variant="outlined"
                            />
                        )}
                    />

                    <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: 'Password is required',
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                fullWidth
                                error={!!errors.password}
                                helperText={errors.password?.message}
                                margin="normal"
                                variant="outlined"
                                slotProps={{
                                    input: {
                                        endAdornment: (
                                            <>
                                                {showPassword ? (
                                                    <IconButton
                                                        tabIndex={-1}
                                                        onClick={() =>
                                                            setShowPassword(
                                                                !showPassword
                                                            )
                                                        }
                                                    >
                                                        <Feather
                                                            name="eye-off"
                                                            size={24}
                                                            color={text}
                                                        />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton
                                                        tabIndex={-1}
                                                        onClick={() =>
                                                            setShowPassword(
                                                                !showPassword
                                                            )
                                                        }
                                                    >
                                                        <Feather
                                                            name="eye"
                                                            size={24}
                                                            color={text}
                                                        />
                                                    </IconButton>
                                                )}
                                            </>
                                        ),
                                    },
                                }}
                            />
                        )}
                    />
                    <Box>
                        <Typography
                            onClick={() => {
                                setResetPasswordDialogOpen(true)
                                onClose()
                            }}
                            variant="body2"
                            textAlign="center"
                            fontFamily="Inter_600SemiBold"
                            color={primary}
                            sx={{
                                cursor: 'pointer',
                                textAlign: 'end',
                            }}
                        >
                            Forgot Password?
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 1,
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        variant="outlined"
                        color="primary"
                        sx={{ width: isSm ? '50%' : '100%' }}
                        onClick={() => {
                            handleSubmit((data) => {
                                signin(data)
                            })()
                        }}
                        disabled={isPending}
                    >
                        {isPending ? (
                            <ActivityIndicator size="small" />
                        ) : (
                            'Login'
                        )}
                    </Button>
                    <Typography
                        variant={isSm ? 'body1' : 'body2'}
                        fontFamily="Inter_700Bold"
                        textAlign="center"
                        sx={{ mt: isSm ? 4 : 2 }}
                    >
                        Don't have an account?{' '}
                        <span
                            style={{ color: primary, cursor: 'pointer' }}
                            onClick={() => {
                                openSignupDialog('student')
                                onClose()
                            }}
                        >
                            Sign up
                        </span>
                    </Typography>
                </DialogActions>
            </Dialog>
        </>
    )
}
