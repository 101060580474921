import React from 'react'
import { Box, Typography } from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import { useNavigation } from '@react-navigation/native'

const SearchResult = ({ result }) => {
    const { textSecondary } = useColorsContext()
    const defaultStyles = useStyles()
    const navigation = useNavigation()
    return (
        <Box
            sx={{ borderBottom: '1px solid #ccc', py: 2, cursor: 'pointer' }}
            onClick={() => {
                navigation.navigate('HelpArticle', {
                    slug: result.slug,
                })
            }}
        >
            <Typography variant="h6" fontFamily={defaultStyles.boldFont}>
                {result.title}
            </Typography>
            <Box>
                <Typography
                    variant="body2"
                    color={textSecondary}
                    fontFamily={defaultStyles.titleFont}
                    textTransform="capitalize"
                >
                    {result.type}s
                </Typography>
            </Box>
        </Box>
    )
}

export default SearchResult
