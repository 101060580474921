import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Link, useRoute } from '@react-navigation/native'
import { useColorsContext, useStyles } from '@utils/styles'
import React from 'react'
import LegalMattersLink from './LegalMattersLink'

const LegalMatterHeader: React.FC = () => {
    const { primary } = useColorsContext()
    const defaultStyles = useStyles()
    const route = useRoute()
    const theme = useTheme()

    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    console.log('isMd', isMd)

    const isActive = (screen: string) => route?.name === screen

    return (
        <Box sx={{ backgroundColor: primary, p: 3 }}>
            <Box
                sx={{
                    maxWidth: 1280,
                    margin: '0 auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 2,
                    }}
                >
                    <img
                        src={require('@assets/legal-matters.svg')}
                        style={{
                            height: isMd ? '60px' : '40px',
                            width: 'auto',
                            marginRight: 12,
                        }}
                    />
                    <Typography
                        variant={isMd ? 'h2' : 'h4'}
                        color="#FFF"
                        fontFamily={defaultStyles.boldFont}
                        letterSpacing="-1.8px"
                    >
                        Legal Matters
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMd ? 'row' : 'column',
                        justifyContent: 'space-evenly',
                        rowGap: 1,
                        width: { xs: '100%', md: '80%' },
                        mb: 1,
                    }}
                >
                    <LegalMattersLink
                        to="/terms-of-service"
                        text="Terms of service"
                        screen="TermsOfService"
                        isActive={isActive('TermsOfService')}
                    />
                    <LegalMattersLink
                        to="/privacy-policy"
                        text="Privacy policy"
                        screen="PrivacyPolicy"
                        isActive={isActive('PrivacyPolicy')}
                    />
                    <LegalMattersLink
                        to="/cancellation-policy"
                        text="Cancellation policy"
                        screen="cancellationpolicy"
                        isActive={isActive('cancellationpolicy')}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMd ? 'row' : 'column',
                        justifyContent: 'space-evenly',
                        rowGap: 1,
                        width: { xs: '100%', md: '80%' },
                    }}
                >
                    <LegalMattersLink
                        to="/community-guidelines"
                        text="Community guidelines"
                        screen="communityguidelines"
                        isActive={isActive('communityguidelines')}
                    />
                    <LegalMattersLink
                        to="/nondiscrimination-policy"
                        text="Nondiscrimination policy"
                        screen="nondiscriminationpolicy"
                        isActive={isActive('nondiscriminationpolicy')}
                    />
                    <LegalMattersLink
                        to="/additional-policies"
                        text="Additional policies"
                        screen="additionalpolicies"
                        isActive={isActive('additionalpolicies')}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default LegalMatterHeader
