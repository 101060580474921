import { examIcons } from '@config/exams'
import { specialtyIcons, subjectIcons } from '@config/subjects'
import { Box, Typography, useTheme } from '@mui/material'
import { useColorsContext } from '@utils/styles'
import { useMemo } from 'react'
import theme from 'react-native-elements/dist/config/theme'

const GroupItems = ({ children, filter = null }) => {
    const { darkMode } = useColorsContext()
    const theme = useTheme()
    const icons = useMemo(() => {
        switch (filter) {
            case 'Subjects':
                return subjectIcons
            case 'Specialties':
                return specialtyIcons
            case 'Exams':
                return examIcons
            default:
                return {}
        }
    }, [filter])

    return children.map((child, index) => {
        return (
            <Box
                key={child.key}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    px: 1,
                }}
            >
                {icons[child.key] && (
                    <Box
                        sx={{
                            width: 24,
                            height: 24,
                            backgroundColor: darkMode
                                ? theme.palette.grey[400]
                                : theme.palette.grey[800],
                            WebkitMask: `url(${icons[child.key]}) no-repeat center `,
                            mask: `url(${icons[child.key]}) no-repeat center `,
                            maskSize: 'contain',
                            WebkitMaskSize: 'contain',
                        }}
                    />
                )}

                <Box sx={{ flex: 1 }}>
                    <Typography variant="body1" fontFamily="Poppins_500Medium">
                        {child}
                    </Typography>
                </Box>
            </Box>
        )
    })
}

export default GroupItems
