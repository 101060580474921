import { Box, Typography } from '@mui/material'
import { useStyles } from '@utils/styles'
import React from 'react'

interface OfferingListItemProps {
    text
}

const OfferingListItem: React.FC<OfferingListItemProps> = ({ text }) => {
    const defaultStyles = useStyles()
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 4,
            }}
        >
            <Box
                component="img"
                sx={{
                    width: {
                        xs: 50,
                        sm: 40,
                        md: 50,
                    },
                }}
                src={require('@assets/images/landing-page/offering-check.svg')}
            />
            <Typography
                fontFamily={defaultStyles.titleFont}
                letterSpacing="-1.08px"
                fontSize={{
                    lg: '1.5rem',
                }}
                sx={{
                    textWrap: {
                        md: 'balance',
                    },
                }}
            >
                {text}
            </Typography>
        </Box>
    )
}

export default OfferingListItem
