import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import React from 'react'
import { StyleSheet } from 'react-native'

export const ContentHeader = () => {
    const { text } = useColorsContext()
    const theme = useTheme()
    const defaultStyles = useStyles()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Box sx={{ padding: 2, display: 'flex' }}>
            <Box sx={{ flex: 1 }}>
                <Typography
                    variant={isMd ? 'h2' : 'h4'}
                    sx={{ fontFamily: defaultStyles.boldFont }}
                >
                    Help Center
                </Typography>
                <Typography
                    variant={isMd ? 'h6' : 'body1'}
                    sx={{ marginTop: isMd ? 2 : 0 }}
                    fontFamily={defaultStyles.titleFont}
                >
                    How can we help?
                </Typography>
            </Box>
            <Box sx={{ pt: 2 }}>
                <Box
                    component="img"
                    src={require('@assets/images/help-center/banner.png')}
                    sx={{ maxHeight: isMd ? 250 : 100 }}
                />
            </Box>
        </Box>
    )
}
