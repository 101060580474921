import { Box, Typography } from '@mui/material'
import { useStyles } from '@utils/styles'

const PerkItem = ({ icon, text }) => {
    const defaultStyles = useStyles()
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mb: 2,
            }}
        >
            <Box
                component="img"
                sx={{
                    objectFit: 'contain',
                    height: { xs: 60, lg: 70 },
                    mb: { lg: 2, xs: 1 },
                }}
                src={require(
                    `@assets/images/become-an-elite-tutor/${icon}.png`
                )}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Box
                    component="img"
                    sx={{ objectFit: 'contain', height: 28, mr: 2 }}
                    src={require(
                        `@assets/images/become-an-elite-tutor/check.png`
                    )}
                />
                <Typography
                    fontFamily={defaultStyles.boldFont}
                    color="#FFF"
                    fontSize={{
                        lg: '1.5rem',
                        md: '1.4rem',
                        sm: '1.6rem',
                        xs: '1.3rem',
                    }}
                >
                    {text}
                </Typography>
            </Box>
        </Box>
    )
}

export default PerkItem
