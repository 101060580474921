import { Box } from '@mui/material'
import React from 'react'
import { Footer } from '@components/Footer'
import MoreQuestionsFooter from '@components/MoreQuestionsFooter.tsx'
import BecomeAnEliteTutorHero from './Sections/BecomeAnEliteTutorHero'
import PerksAndBenefits from './Sections/PerksAndBenefits'
import AchievingEliteTutor from './Sections/AchievingEliteTutor'

const BecomeAnEliteTutor: React.FC = () => {
    return (
        <Box
            sx={{
                flex: 1,
                backgroundColor: '#FCFCFC',
                width: '100%',
                overflowY: 'auto',
            }}
        >
            <BecomeAnEliteTutorHero />
            <PerksAndBenefits />
            <AchievingEliteTutor />
            <MoreQuestionsFooter />
            <Footer />
        </Box>
    )
}

export default BecomeAnEliteTutor
