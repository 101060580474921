import { LoginDialog } from '@components/LoginDialog'
import OnboardingDialog from '@components/OnboardingDialog'
import { SignUpDialog } from '@components/SignUpDialog'
import React, { createContext, useState, useContext, ReactNode } from 'react'

interface DialogContextProps {
    openOnboardingDialog: () => void
    openLoginDialog: (subtitle?: string) => void
    setShowLoginModal: (open: boolean) => void
    openSignupDialog: (type: 'tutor' | 'student') => void
}

const DialogContext = createContext<DialogContextProps | undefined>(undefined)

export const DialogProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [showOnboardingDialog, setShowOnboardingDialog] = useState(false)
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [loginSubtitle, setLoginSubtitle] = useState('')
    const [showSignupDialog, setShowSignupDialog] = useState(false)
    const [signupType, setSignupType] = useState<'student' | 'tutor'>('student')

    const openLoginDialog = (subtitle?: string) => {
        setLoginSubtitle(subtitle)
        setShowLoginModal(true)
    }

    const openOnboardingDialog = () => {
        setShowOnboardingDialog(true)
    }

    const openSignupDialog = (type: 'tutor' | 'student') => {
        setShowSignupDialog(true)
        setSignupType(type)
    }

    return (
        <DialogContext.Provider
            value={{
                openOnboardingDialog,
                openLoginDialog,
                openSignupDialog,
                setShowLoginModal,
            }}
        >
            <OnboardingDialog
                visible={showOnboardingDialog}
                setVisible={setShowOnboardingDialog}
            />
            <LoginDialog
                loginModal={showLoginModal}
                setLoginModal={setShowLoginModal}
                subTitle={loginSubtitle}
                openSignupDialog={openSignupDialog}
            />
            <SignUpDialog
                open={showSignupDialog}
                setOpen={setShowSignupDialog}
                openLogin={openLoginDialog}
                type={signupType}
            />
            {children}
        </DialogContext.Provider>
    )
}

export const useDialog = (): DialogContextProps => {
    const context = useContext(DialogContext)
    if (!context) {
        throw new Error('useDialog must be used within a DialogProvider')
    }
    return context
}
