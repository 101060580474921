import React from 'react'
import { HelpStackParamList } from 'navigators/HelpStack'
import { articles } from '../data'
import {
    Box,
    Container,
    Typography,
    useMediaQuery,
    useTheme,
    Button,
    CircularProgress,
    Grid,
    Grid2,
} from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import { useEffect, useState } from 'react'
import { Footer } from '@components/Footer'
import parse from 'html-react-parser'
import useLinkifyHtml from '@hooks/useLinkifyHtml'
import Links from './Links'
import AntDesign from '@expo/vector-icons/AntDesign'

interface ArticleType {
    title: string
    slug: string
    featured: boolean
    type: string
    category_id: number
    body: string | undefined
}

const HelpArticle: React.FC<HelpStackParamList['HelpArticle']> = ({
    route,
    navigation,
}) => {
    const { background, text, textSecondary } = useColorsContext()
    const theme = useTheme()
    const defaultStyles = useStyles()

    const isMd = useMediaQuery(theme.breakpoints.up('md'))

    const { slug } = route.params
    const [article, setArticle] = useState<ArticleType>(null)
    const [loading, setLoading] = useState(true)

    const { html, links } = useLinkifyHtml(article?.body)

    useEffect(() => {
        const index = articles.findIndex((article) => article.slug === slug)
        if (index >= 0) {
            setArticle(articles[index])
        }
        setTimeout(() => {
            setLoading(false)
        }, 400)
    }, [])

    useEffect(() => {
        if (article) {
            navigation.setOptions({ title: article.title })
        }
    }, [article])

    return (
        <Box
            sx={{ overflowY: 'scroll', backgroundColor: background }}
            className="help-article"
        >
            <Box
                sx={{
                    flexDirection: 'column',
                    minHeight: '80vh',
                }}
            >
                <Container sx={{ padding: isMd ? 4 : 2 }}>
                    {html ? (
                        <Grid2 container spacing={2}>
                            <Grid2 size={{ xs: 12, md: 10 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mt: 2,
                                    }}
                                >
                                    <Typography
                                        color={text}
                                        variant="h6"
                                        fontFamily="Poppins_500Medium"
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            navigation.navigate('Help')
                                        }}
                                    >
                                        Tutred &gt;
                                    </Typography>
                                    <Typography
                                        color={text}
                                        variant="h6"
                                        fontFamily="Poppins_500Medium"
                                        textTransform="capitalize"
                                        sx={{ cursor: 'pointer', ml: 0.5 }}
                                        onClick={() => {
                                            navigation.navigate('Help', {
                                                tab: article?.type || 'student',
                                            })
                                        }}
                                    >
                                        {article.type} &gt;
                                    </Typography>

                                    <Typography
                                        color={text}
                                        variant="h6"
                                        fontFamily="Poppins_500Medium"
                                        sx={{
                                            ml: 0.5,
                                        }}
                                    >
                                        {article?.title}
                                    </Typography>
                                </Box>
                            </Grid2>
                            <Grid2 size={{ xs: 12, md: 10 }}>
                                <Typography
                                    variant={isMd ? 'h3' : 'h4'}
                                    sx={{
                                        fontFamily: defaultStyles.boldFont,
                                        marginBottom: 8,
                                    }}
                                >
                                    {article.title}
                                </Typography>

                                <div
                                    style={{ fontFamily: 'Poppins_400Regular' }}
                                >
                                    {parse(`${html}`)}
                                </div>
                            </Grid2>
                            <Grid2 size={{ xs: 0, md: 2 }}>
                                <Links links={links} />
                            </Grid2>
                        </Grid2>
                    ) : (
                        <Box
                            sx={{
                                p: 6,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                height: '40vh',
                            }}
                        >
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                <>
                                    <Typography
                                        variant="h1"
                                        sx={{
                                            fontFamily: defaultStyles.boldFont,
                                        }}
                                    >
                                        404
                                    </Typography>
                                    <Typography
                                        variant={isMd ? 'h4' : 'h5'}
                                        sx={{
                                            fontFamily: defaultStyles.boldFont,
                                            marginBottom: 6,
                                        }}
                                    >
                                        Article not found
                                    </Typography>

                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={async () => {
                                            navigation.navigate('Help')
                                        }}
                                    >
                                        Go Back
                                    </Button>
                                </>
                            )}
                        </Box>
                    )}
                </Container>
            </Box>
            <Footer />
        </Box>
    )
}

export default HelpArticle
