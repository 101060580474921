import React from 'react'
import {
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    useTheme,
    useMediaQuery,
    Grid2,
} from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import AchievingEliteTutorItem from './AchievingEliteTutorItem'

const AchievingEliteTutor = () => {
    const { link, primaryLight } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const isLg = useMediaQuery(theme.breakpoints.up('lg'))

    return (
        <Box
            sx={{
                p: { md: 6, sm: 2, xs: 2 },
                maxWidth: 1280,
                margin: 'auto',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Typography
                fontFamily={defaultStyles.titleFont}
                textAlign="center"
                letterSpacing="-2.52px"
                fontSize={{
                    lg: '3.8rem',
                    md: '3rem',
                    sm: '2.5rem',
                    xs: '2rem',
                }}
            >
                How to achieve <span style={{ color: link }}>Elite Tutor</span>{' '}
                status?
            </Typography>
            <Typography
                fontFamily={defaultStyles.mediumFont}
                textAlign="center"
                letterSpacing="-1.8px"
                sx={{
                    textWrap: 'balance',
                }}
                fontSize={{
                    lg: '1.4rem',
                    xs: '1.1rem',
                }}
            >
                Every three months, we’ll check if your performance meets the
                following benchmarks for the previous 365 days. If it does,
                you’ll earn Elite Tutor status for the next cycle.
            </Typography>
            <Grid2
                container
                borderRadius={6}
                sx={{
                    backgroundColor: primaryLight,
                    p: 4,
                    my: 4,
                }}
                spacing={8}
            >
                <AchievingEliteTutorItem
                    icon="rating"
                    title="90%+ five-star rate"
                    text="Elite Tutors get at least 90% overall 5-star ratings for all completed lessons,  so your students know they can expect a great learning experience."
                />
                <AchievingEliteTutorItem
                    icon="completed-lessons"
                    title="50+ completed lessons"
                    text="Elite Tutors have completed at least 50 lessons. Your students can feel confident knowing they’re in experienced hands."
                />
                <AchievingEliteTutorItem
                    icon="response-rate"
                    title="95%+ response rate"
                    text="Elite Tutors maintain a high level of responsiveness by replying to messages within a timely manner. This ensures a seamless experience for learners and helps build trust."
                />
                <AchievingEliteTutorItem
                    icon="id-verified"
                    title="ID verified"
                    text="Elite Tutors undergo a secure ID verification process, giving students peace of mind and reinforcing professionalism on the platform."
                />
            </Grid2>
        </Box>
    )
}

export default AchievingEliteTutor
