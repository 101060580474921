import React from 'react'
import {
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { useStyles } from '@utils/styles'
import {
    NavigationProp,
    ParamListBase,
    useNavigation,
} from '@react-navigation/native'
import { useDialog } from '@contexts/dialogContext'

const Opportunities = () => {
    const defaultStyles = useStyles()
    const theme = useTheme()

    const isMd = useMediaQuery(theme.breakpoints.up('md'))

    const navigation: NavigationProp<ParamListBase> = useNavigation()
    const { openSignupDialog } = useDialog()

    return (
        <Box
            sx={{
                p: { md: 6, sm: 4, xs: 2 },
                maxWidth: 1280,
                margin: '0 auto',
            }}
        >
            <Card
                sx={{
                    backgroundColor: '#0048FF',
                    borderRadius: 6,

                    position: 'relative',
                    overflow: 'hidden',
                }}
                elevation={0}
            >
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        p: { xs: 2, sm: 3, md: 4 },
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: { xs: 'flex-end', sm: 'flex-start' },
                        }}
                    >
                        <Box sx={{ flex: 1 }}>
                            {' '}
                            <Typography
                                sx={{
                                    color: '#FFF',
                                    mb: 2,
                                }}
                                letterSpacing="-1.08px"
                                fontFamily={defaultStyles.titleFont}
                                fontSize={{
                                    xs: '1.6rem',
                                    sm: '2.2rem',
                                    md: '3rem',
                                    lg: '3.5rem',
                                }}
                            >
                                Discover new teaching
                                <br /> opportunities
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#FFF',

                                    fontSize: {
                                        xs: '1.2rem',
                                        md: '1.8rem',
                                        lg: '2rem',
                                    },
                                    textWrap: 'balance',
                                }}
                                letterSpacing="-1.08px"
                                fontFamily="Poppins_500Medium"
                                variant="body1"
                            >
                                At{' '}
                                <span
                                    style={{
                                        color: '#000',
                                        fontFamily: defaultStyles.titleFont,
                                    }}
                                >
                                    tutred
                                </span>{' '}
                                you have the flexibility to decide your rates
                                and availability!
                            </Typography>
                        </Box>
                        <Box
                            component="img"
                            src={require('@assets/images/landing-page/opportunities1.svg')}
                            alt="landing-page-image"
                            sx={{
                                width: { xs: 0, sm: '100%' },
                                maxWidth: '28%',
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: 'center',
                            mt: 3,
                            gap: { md: 4, xs: 2 },
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="info"
                            fullWidth
                            sx={{
                                fontFamily: defaultStyles.boldFont,
                                px: { sm: 0 },
                                width: {
                                    sm: 400,
                                },
                                height: { xs: 48, sm: 50, md: 52 },
                            }}
                            onClick={() => {
                                openSignupDialog('tutor')
                            }}
                        >
                            Become a tutor
                        </Button>
                        <Button
                            variant="outlined"
                            color="info"
                            fullWidth
                            sx={{
                                fontFamily: defaultStyles.boldFont,
                                px: { sm: 0 },
                                width: {
                                    sm: 400,
                                },
                                height: { xs: 48, sm: 50, md: 52 },
                            }}
                            onClick={() => {
                                navigation.navigate('BecomeATutor')
                            }}
                        >
                            Learn more
                        </Button>

                        <Box
                            component="img"
                            src={require('@assets/images/landing-page/opportunities2.svg')}
                            alt="landing-page-image"
                            sx={{
                                maxWidth: { xs: '100%', sm: '40%' },
                                mt: { xs: 3, sm: 0 },
                            }}
                        />
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}

export default Opportunities
