import { Box, Grid2, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import React from 'react'

const TeachAndEarn: React.FC = () => {
    const { primaryLight } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const isSm = useMediaQuery(theme.breakpoints.down('sm'))
    return (
        <Grid2
            container
            spacing={6}
            sx={{
                padding: {
                    xs: 2,
                    md: 6,
                },
                maxWidth: 1280,
                margin: 'auto',
                overflow: 'hidden',
            }}
        >
            <Grid2 size={{ xs: 12, md: 7 }}>
                <Grid2
                    container
                    sx={{ mb: 2 }}
                    spacing={{ xs: 2, md: 6 }}
                    alignItems="center"
                >
                    <Grid2
                        size={{ xs: 3, md: 2 }}
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            component="img"
                            sx={{ width: { xs: 80, sm: 100, md: 120 } }}
                            src={require('@assets/images/become-a-tutor/pig.png')}
                        />
                    </Grid2>

                    <Grid2 size={{ xs: 9, md: 10 }}>
                        <Typography
                            variant={isMd ? 'h4' : 'h5'}
                            fontFamily={defaultStyles.boldFont}
                            letterSpacing="-1.08px"
                        >
                            Earn 90% of the lesson rate
                        </Typography>
                        <Typography
                            variant={isMd ? 'h6' : 'body1'}
                            fontFamily="Poppins_500Medium"
                            letterSpacing="-1.8px"
                        >
                            Maximize your earnings by keeping 90% of the fees
                            for every lesson you teach. Tutred’s low commission
                            ensures you get the most out of your expertise.
                        </Typography>
                    </Grid2>
                </Grid2>
                <Grid2
                    container
                    sx={{ mb: 2 }}
                    spacing={{ xs: 2, md: 6 }}
                    alignItems="center"
                >
                    <Grid2
                        size={{ xs: 3, md: 2 }}
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            component="img"
                            sx={{ width: { xs: 80, sm: 100, md: 110 } }}
                            src={require('@assets/images/become-a-tutor/cap.png')}
                        />
                    </Grid2>

                    <Grid2 size={{ xs: 9, md: 10 }}>
                        <Typography
                            variant={isMd ? 'h4' : 'h5'}
                            fontFamily={defaultStyles.boldFont}
                            letterSpacing="-1.08px"
                        >
                            Choose your own rates
                        </Typography>
                        <Typography
                            variant={isMd ? 'h6' : 'body1'}
                            fontFamily="Poppins_500Medium"
                            letterSpacing="-1.8px"
                        >
                            Set your own hourly rate to reflect your skills and
                            experience. You have full control over how much you
                            earn per lesson.
                        </Typography>
                    </Grid2>
                </Grid2>
                <Grid2
                    container
                    sx={{ mb: 2 }}
                    spacing={{ xs: 2, md: 6 }}
                    alignItems="center"
                >
                    <Grid2
                        size={{ xs: 3, md: 2 }}
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            component="img"
                            sx={{ width: { xs: 80, sm: 100, md: 100 } }}
                            src={require('@assets/images/become-a-tutor/wallet.png')}
                        />
                    </Grid2>
                    <Grid2 size={{ xs: 9, md: 10 }}>
                        <Typography
                            variant={isMd ? 'h4' : 'h5'}
                            fontFamily={defaultStyles.boldFont}
                            letterSpacing="-1.08px"
                        >
                            Instant Withdrawals
                        </Typography>
                        <Typography
                            variant={isMd ? 'h6' : 'body1'}
                            fontFamily="Poppins_500Medium"
                            letterSpacing="-1.8px"
                        >
                            Access your earnings immediately after your lesson.
                            With auto-withdrawals, you’re never left waiting for
                            your hard-earned money.
                        </Typography>
                    </Grid2>
                </Grid2>
            </Grid2>

            <Grid2
                size={{ xs: 12, md: 5 }}
                sx={{
                    backgroundColor: primaryLight,
                    borderRadius: 4,
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Typography
                    fontFamily={defaultStyles.titleFont}
                    variant={isMd ? 'h3' : 'h4'}
                    textAlign="center"
                    lineHeight={1.5}
                    letterSpacing="-1.8px"
                >
                    Teach & earn on <br />
                    your own terms!
                </Typography>
                <Box
                    component="img"
                    sx={{ maxHeight: { xs: 290, md: 325 }, pt: 4 }}
                    src={require('@assets/images/become-a-tutor/money-hands.png')}
                />
            </Grid2>
        </Grid2>
    )
}

export default TeachAndEarn
