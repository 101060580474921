import React from 'react'
import { Box, Typography } from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'

const FilterButton = ({ label, isSelected, onClick }) => {
    const { textSecondary, grey } = useColorsContext()
    const defaultStyles = useStyles()

    return (
        <Box
            sx={{
                py: 1,
                px: 3,
                borderRadius: 2,
                border: '3px solid #000',
                backgroundColor: isSelected ? grey : 'transparent',
                cursor: 'pointer',
            }}
            onClick={onClick}
        >
            <Typography
                variant="body1"
                color={isSelected ? '#FFF' : textSecondary}
                fontFamily={defaultStyles.titleFont}
                textTransform="capitalize"
            >
                {label}
            </Typography>
        </Box>
    )
}

export default FilterButton
