import React from 'react'
import {
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    useTheme,
    useMediaQuery,
    Grid2,
} from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import { useDialog } from '@contexts/dialogContext'
import {
    NavigationProp,
    ParamListBase,
    useNavigation,
} from '@react-navigation/native'

const LandingPageHero = () => {
    const { primary } = useColorsContext()
    const defaultStyles = useStyles()
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))
    const { openSignupDialog } = useDialog()

    const navigation: NavigationProp<ParamListBase> = useNavigation()

    return (
        <Box
            sx={{
                backgroundColor: primary,
            }}
        >
            <Grid2
                container
                sx={{
                    p: { xs: 2, sm: 3, md: 4 },
                    position: 'relative',
                    overflow: 'hidden',
                    borderRadius: 0,
                    zIndex: 99,
                    maxWidth: 1280,
                    margin: 'auto',
                    backgroundColor: 'transparent',
                }}
                spacing={4}
            >
                <Grid2
                    size={{ xs: 12, sm: 6 }}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box>
                        <Box
                            sx={{
                                display: isSm ? 'block' : 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#FFF',
                                    mb: 2,
                                    fontSize: {
                                        xs: '1.65rem',
                                        sm: '2.5rem',
                                        md: '3.5rem',
                                        lg: '4.4rem',
                                    },
                                    lineHeight: 1.5,
                                    letterSpacing: '-1.8px',
                                }}
                                fontFamily={defaultStyles.titleFont}
                                variant="h2"
                            >
                                Explore a new
                                <br /> way of learning!
                            </Typography>
                            {!isSm && (
                                <Box
                                    component="img"
                                    src={require('@assets/images/landing-page/hero.svg')}
                                    alt="landing-page-image"
                                    sx={{
                                        height: 100,
                                        objectFit: 'contain',
                                    }}
                                />
                            )}
                        </Box>

                        <Typography
                            sx={{
                                color: '#FFF',
                                fontSize: {
                                    xs: '1rem',
                                    sm: '1.3rem',
                                    md: '1.65rem',
                                    lg: '2rem',
                                },

                                letterSpacing: '-1.08px',
                            }}
                            fontFamily="Poppins_500Medium"
                            variant="h4"
                        >
                            Connect to the right tutor instantly
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: 'center',
                            mt: 3,
                            gap: { md: 4, xs: 2 },
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="info"
                            fullWidth
                            sx={{
                                fontFamily: defaultStyles.boldFont,
                                fontSize: {
                                    xs: '1.25rem',
                                    sm: '1rem',
                                    md: '1.25rem',
                                },
                                px: { sm: 0 },
                                width: {
                                    sm: 300,
                                    md: 400,
                                },
                                height: { xs: 48, sm: 50, md: 52 },
                            }}
                            onClick={() => {
                                openSignupDialog('student')
                            }}
                        >
                            Get started
                            <Box
                                component="img"
                                sx={{
                                    position: 'absolute',
                                    height: { xs: 48, sm: 36, md: 48 },
                                    objectFit: 'contain',
                                    right: { md: 12, sm: 4, xs: 12 },
                                }}
                                src={require('@assets/images/landing-page/cursor.png')}
                            />
                        </Button>
                        <Button
                            variant="outlined"
                            color="info"
                            fullWidth
                            sx={{
                                fontFamily: defaultStyles.boldFont,
                                fontSize: {
                                    xs: '1.25rem',
                                    sm: '1rem',
                                    md: '1.25rem',
                                },
                                px: { sm: 0 },
                                width: {
                                    sm: 300,
                                    md: 400,
                                },
                                height: { xs: 48, sm: 50, md: 52 },
                            }}
                            onClick={() => {
                                navigation.navigate('Tutors', {
                                    screen: 'TutorList',
                                })
                            }}
                        >
                            Browse Tutors
                        </Button>
                    </Box>
                </Grid2>
                {isSm && (
                    <Grid2 size={6} display="flex">
                        <Box
                            component="img"
                            src={require('@assets/images/landing-page/hero.svg')}
                            alt="landing-page-image"
                            sx={{
                                width: '100%',
                                maxHeight: { lg: 450, md: 375 },
                                objectFit: 'contain',
                            }}
                        />
                    </Grid2>
                )}
            </Grid2>
        </Box>
    )
}

export default LandingPageHero
