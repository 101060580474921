import React, { useEffect, useMemo, useState } from 'react'
import {
    Picker,
    ScrollView,
    TouchableOpacity,
    Platform,
    SafeAreaView,
    View,
    Text,
    StyleSheet,
} from 'react-native'
import NumericTextInput from '@wwdrew/react-native-numeric-textinput'
import { useMediaQuery } from 'react-responsive'
import { useColorsContext, useStyles } from '../../../utils/styles'
import { LoadingPage } from '../../../components/LoadingPage'
import { useUserContext } from '../../../contexts/userContext'
import { Footer } from '../../../components/Footer'
import { useAlert } from 'react-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { AntDesign, Feather } from '@expo/vector-icons'
import { Link } from '@react-navigation/native'
import { useFirebaseContext } from '../../../contexts/firebaseContext'
import Animated, { FadeInDown, FadeOutDown } from 'react-native-reanimated'

// Firebase
import { doc, updateDoc } from 'firebase/firestore'
import useInstaBookTutorial from '../../../hooks/useInstaBookTutorial'
import { MultiSwitchToggle } from '@components/MultiSwitchToggle'
import { Typography } from '@mui/material'
import { decode } from 'html-entities'
import TutredSwitch from '@components/TutredSwitch'

// Account Screen
export const InstaBook = () => {
    const { user, instaBook, preLiminaryWindow, extendedUser } =
        useUserContext()

    const customAlert = useAlert()
    const { db } = useFirebaseContext()

    const instaBookTutorial = useInstaBookTutorial()

    // User Context
    const [loading, setLoading] = useState(true)
    const [localPreliminaryWindow, setLocalPreliminaryWindow] =
        useState(preLiminaryWindow)
    const [keepInstaBookOn, setKeepInstaBookOn] = useState(
        extendedUser.keepInstaBookOn
    )
    const [instaBookExtensionRate, setInstabookExtensionRate] = useState(
        extendedUser?.instaBookExtensionRate || 1
    )
    const [instaBookPricing, setInstaBookPricing] = useState({
        15: {
            length: 15,
            rate: 15,
        },
        30: {
            length: 30,
            rate: 30,
        },
        45: {
            length: 45,
            rate: 45,
        },
        60: {
            length: 60,
            rate: 60,
        },
    })
    useEffect(() => {
        setLocalPreliminaryWindow(preLiminaryWindow)
        setKeepInstaBookOn(extendedUser.keepInstaBookOn)
        setInstabookExtensionRate(extendedUser?.instaBookExtensionRate || 1)
        setInstaBookPricing(
            extendedUser?.instaBookPricing || {
                15: {
                    length: 15,
                    rate: 15,
                },
                30: {
                    length: 30,
                    rate: 30,
                },
                45: {
                    length: 45,
                    rate: 45,
                },
                60: {
                    length: 60,
                    rate: 60,
                },
            }
        )
    }, [
        extendedUser?.keepInstaBookOn,
        extendedUser?.instaBookPricing,
        extendedUser?.instaBookExtensionRate,
        preLiminaryWindow,
    ])

    const pricingOptions = useMemo(() => {
        if (instaBookPricing) {
            return Object.keys(instaBookPricing).map((key) => {
                const pricing = instaBookPricing[key]
                return {
                    label: `${key} mins`,
                    contextLabel: ` ${decode('\u00b7')} CA$${pricing.rate || 0}`,
                    value: pricing,
                }
            })
        } else {
            return []
        }
    }, [
        instaBookPricing?.[15]?.rate,
        instaBookPricing?.[30]?.rate,
        instaBookPricing?.[45]?.rate,
        instaBookPricing?.[60]?.rate,
    ])

    const [selectedInstaBookPricingOption, setSelectedInstaBookPricingOption] =
        useState(0)

    // Colors and styles
    const {
        primary,
        background,
        backgroundVariant,
        text,
        textVariant,
        border,
        red,
    } = useColorsContext()
    const defaultStyles = useStyles()

    // Responsive Breakpoints
    const isLg = useMediaQuery({ query: '(min-width: 1024px)' })
    const isMd = useMediaQuery({ query: '(min-width: 768px)' })
    const isSm = useMediaQuery({ query: '(min-width: 640px)' })

    const AnimatedText = Animated.createAnimatedComponent(Text)

    // Create stylesheet
    const styles = StyleSheet.create({
        container: {
            backgroundColor: background,
            flex: 1,
        },
        maxWidth: {
            flex: 1,
            backgroundColor: background,
            display: 'flex',
            flexDirection: Platform.OS === 'web' ? 'row' : null,
            paddingRight: 0,
            width: '100%',
            margin: Platform.OS === 'web' ? 'auto' : null,
        },
        mainSection: {
            flex: 1,
            padding: isSm ? 45 : 15,
            paddingTop: 30,
        },
        title: {
            fontSize: 36,
            fontFamily: defaultStyles.titleFont,
            color: text,
        },
        subtitle: {
            fontSize: 16,
            fontFamily: defaultStyles.boldFont,
            color: text,
        },
        description: {
            fontSize: 16,
            fontFamily: defaultStyles.regularFont,
            color: textVariant,
        },
        infoTitle: {
            textAlign: 'left',
            fontSize: 22,
            fontFamily: defaultStyles.boldFont,
            color: text,
        },
        infoSubtitle: {
            fontSize: 19,
            fontFamily: defaultStyles.boldFont,
            color: textVariant,
        },
        infoDescription: {
            fontSize: 16,
            fontFamily: defaultStyles.regularFont,
            color: textVariant,
            marginTop: 5,
            display: 'flex',
            flex: 1,
        },
        infoLink: {
            fontSize: 14,
            fontFamily: defaultStyles.regularFont,
            color: primary,
            marginTop: 10,
        },
        picker: {
            height: 50,
            color: text,
            borderRadius: defaultStyles.radius,
            fontFamily: defaultStyles.boldFont,
            paddingLeft: 15,
            paddingRight: 15,
            backgroundColor: backgroundVariant,
            border: '1px solid ' + border,
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
        },
        textInput: {
            height: 50,
            color: text,
            fontSize: 16,
            fontFamily: defaultStyles.boldFont,
        },
        textInputPrefix: {
            fontFamily: defaultStyles.boldFont,
            fontSize: 16,
        },
        error: {
            color: red,
            marginTop: 4,
            textAlign: 'right',
        },
    })

    useEffect(() => {
        if (user) {
            setLoading(false)
        }
    }, [user])

    const [formErrors, setFormErrors] = useState({
        instaBookExtensionRate: null,
    })

    const handleSave = () => {
        if (formErrors.instaBookExtensionRate) {
            customAlert.error('Please correct errors on the form before saving')
            return
        }
        updateDoc(doc(db, 'users', user.uid), {
            preLiminaryWindow: parseFloat(localPreliminaryWindow),
            keepInstaBookOn: keepInstaBookOn,
            instaBookPricing: instaBookPricing,
            instaBookExtensionRate: instaBookExtensionRate,
        })
            .then(() => {
                customAlert.success('Settings saved successfully!')
            })
            .catch((error) => {
                customAlert.error('Failed to save settings: ' + error.message)
            })
    }

    const preliminaryOptions = [
        {
            label: '1 min',
            value: 1,
        },
        {
            label: '1.5 min',
            value: 1.5,
        },
        {
            label: '2 min',
            value: 2,
        },
        {
            label: '2.5 mins',
            value: 2.5,
        },
        {
            label: '3 min',
            value: 3,
        },
        {
            label: '3.5 mins',
            value: 3.5,
        },
        {
            label: '4 min',
            value: 4,
        },
        {
            label: '4.5 mins',
            value: 4.5,
        },
        {
            label: '5 min',
            value: 5,
        },
    ]

    useEffect(() => {
        if (instaBookExtensionRate < 0.5) {
            setFormErrors({
                ...formErrors,
                instaBookExtensionRate:
                    'Session extension fee must be at least $0.50 per minute',
            })
        } else if (instaBookExtensionRate > 5) {
            setFormErrors({
                ...formErrors,
                instaBookExtensionRate:
                    'Session extension fee must be at most $5.00 per minute',
            })
        } else {
            setFormErrors({ ...formErrors, instaBookExtensionRate: null })
        }
    }, [instaBookExtensionRate])

    const instaBookExtensionRateError = useMemo(() => {
        if (formErrors?.instaBookExtensionRate) {
            return (
                <AnimatedText
                    entering={FadeInDown}
                    exiting={FadeOutDown}
                    style={styles.error}
                >
                    {' '}
                    {formErrors.instaBookExtensionRate}
                </AnimatedText>
            )
        } else {
            return null
        }
    }, [formErrors?.instaBookExtensionRate])

    return (
        <SafeAreaView style={styles.container}>
            {loading ? (
                <LoadingPage />
            ) : (
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={{ backgroundColor: background }}
                >
                    <View style={styles.maxWidth}>
                        <View style={[styles.mainSection]}>
                            <Text
                                style={{
                                    color: text,
                                    fontSize: 16,
                                    fontFamily: defaultStyles.regularFont,
                                    marginBottom: 5,
                                }}
                            >
                                <Link to={'/settings'}>
                                    <Text>Settings</Text>
                                </Link>{' '}
                                <Text
                                    style={{
                                        fontFamily: defaultStyles.boldFont,
                                        color: primary,
                                    }}
                                >
                                    <Feather
                                        name="chevron-right"
                                        size={15}
                                        color={text}
                                    />{' '}
                                    InstaBook
                                </Text>
                            </Text>

                            <Text style={styles.title}>InstaBook settings</Text>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <Text
                                    style={{
                                        color: text,
                                        fontSize: 16,
                                        fontFamily: defaultStyles.regularFont,
                                    }}
                                >
                                    InstaBook status: {instaBook ? 'On' : 'Off'}
                                </Text>
                            </View>

                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: isLg ? 'row' : 'column',
                                    gap: 30,
                                }}
                            >
                                <View
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flex: 1,
                                        paddingRight: 30,
                                        marginTop: 30,
                                    }}
                                >
                                    <View
                                        style={{
                                            borderBottomColor: border,
                                            borderBottomWidth: 1,
                                            paddingBottom: 15,
                                            marginBottom: 15,
                                            display: 'flex',
                                            backgroundColor: background,
                                            flexDirection: 'column',
                                            zIndex: 1,
                                        }}
                                    >
                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: isMd
                                                    ? 'row'
                                                    : 'column',
                                                justifyContent: 'space-between',
                                                alignItems: isMd
                                                    ? 'center'
                                                    : 'flex-start',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    maxWidth: '70%',
                                                }}
                                            >
                                                <Text style={styles.subtitle}>
                                                    Session length prices
                                                </Text>
                                                <Text
                                                    style={styles.description}
                                                >
                                                    Set your rate based on
                                                    session length.
                                                </Text>
                                            </View>
                                            <MultiSwitchToggle
                                                options={pricingOptions}
                                                selectedIndex={
                                                    selectedInstaBookPricingOption
                                                }
                                                setSelectedIndex={
                                                    setSelectedInstaBookPricingOption
                                                }
                                            />
                                        </View>

                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: isMd
                                                    ? 'row'
                                                    : 'column',
                                                justifyContent: 'space-between',
                                                alignItems: isMd
                                                    ? 'center'
                                                    : 'flex-start',
                                                paddingTop: 15,
                                            }}
                                        >
                                            <View
                                                style={{
                                                    maxWidth: '70%',
                                                }}
                                            >
                                                <Text style={styles.subtitle}>
                                                    Session Fee
                                                </Text>
                                                <Text
                                                    style={styles.description}
                                                >
                                                    Set the fee for the selected
                                                    session length.
                                                </Text>
                                            </View>

                                            <NumericTextInput
                                                style={[styles.picker]}
                                                type="currency"
                                                locale="en-Us"
                                                currency="CAD"
                                                decimalPlaces={2}
                                                value={
                                                    pricingOptions[
                                                        selectedInstaBookPricingOption
                                                    ].value.rate
                                                }
                                                onUpdate={(value) => {
                                                    setInstaBookPricing({
                                                        ...instaBookPricing,
                                                        [pricingOptions[
                                                            selectedInstaBookPricingOption
                                                        ].value.length]: {
                                                            rate: value,
                                                            length: pricingOptions[
                                                                selectedInstaBookPricingOption
                                                            ].value.length,
                                                        },
                                                    })
                                                }}
                                                placeholder="$0.00"
                                            />
                                        </View>
                                    </View>
                                    <View
                                        style={{
                                            borderBottomColor: border,
                                            borderBottomWidth: 1,
                                            paddingBottom: 15,
                                            marginBottom: 15,
                                        }}
                                    >
                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: isMd
                                                    ? 'row'
                                                    : 'column',
                                                justifyContent: 'space-between',
                                                alignItems: isMd
                                                    ? 'center'
                                                    : 'flex-start',
                                                backgroundColor: background,
                                                zIndex: 1,
                                            }}
                                        >
                                            <View
                                                style={{
                                                    maxWidth: '70%',
                                                }}
                                            >
                                                <Text style={styles.subtitle}>
                                                    Session extension fee
                                                </Text>
                                                <Text
                                                    style={styles.description}
                                                >
                                                    Set the per minute rate for
                                                    session extensions.
                                                </Text>
                                            </View>
                                            <View
                                                style={[
                                                    styles.picker,
                                                    {
                                                        color: formErrors?.instaBookExtensionRate
                                                            ? red
                                                            : text,
                                                        border: formErrors?.instaBookExtensionRate
                                                            ? '1px solid ' + red
                                                            : '1px solid ' +
                                                              border,
                                                    },
                                                ]}
                                            >
                                                <NumericTextInput
                                                    style={{ outline: 'none' }}
                                                    type="currency"
                                                    locale="en-Us"
                                                    currency="CAD"
                                                    decimalPlaces={2}
                                                    value={
                                                        instaBookExtensionRate
                                                    }
                                                    onUpdate={(value) => {
                                                        setInstabookExtensionRate(
                                                            value
                                                        )
                                                    }}
                                                    placeholder="$0.00"
                                                />
                                                <Typography
                                                    color={text}
                                                    variant="body1"
                                                >
                                                    /min
                                                </Typography>
                                            </View>
                                        </View>
                                        {instaBookExtensionRateError}
                                    </View>

                                    <View
                                        style={{
                                            borderBottomColor: border,
                                            borderBottomWidth: 1,
                                            paddingBottom: 15,
                                            marginBottom: 15,
                                            display: 'flex',
                                            flexDirection: isMd
                                                ? 'row'
                                                : 'column',
                                            justifyContent: 'space-between',
                                            alignItems: isMd
                                                ? 'center'
                                                : 'flex-start',
                                            backgroundColor: background,
                                            zIndex: 99,
                                        }}
                                    >
                                        <View
                                            style={{
                                                maxWidth: '70%',
                                            }}
                                        >
                                            <Text style={styles.subtitle}>
                                                Preliminary Window
                                            </Text>
                                            <Text style={styles.description}>
                                                Set the preliminary window
                                            </Text>
                                        </View>
                                        <Picker
                                            selectedValue={
                                                localPreliminaryWindow
                                            }
                                            style={styles.picker}
                                            onValueChange={(itemValue) => {
                                                setLocalPreliminaryWindow(
                                                    parseFloat(itemValue)
                                                )
                                            }}
                                        >
                                            {preliminaryOptions.map(
                                                ({ label, value }) => (
                                                    <Picker.Item
                                                        label={label}
                                                        value={value}
                                                    />
                                                )
                                            )}
                                        </Picker>
                                    </View>

                                    {/* Auto accept */}
                                    <View
                                        style={{
                                            borderBottomColor: border,
                                            borderBottomWidth: 1,
                                            paddingBottom: 15,
                                            marginBottom: 15,
                                            display: 'flex',
                                            flexDirection: isMd
                                                ? 'row'
                                                : 'column',
                                            justifyContent: 'space-between',
                                            alignItems: isMd
                                                ? 'center'
                                                : 'flex-start',
                                        }}
                                    >
                                        <View
                                            style={{
                                                maxWidth: '70%',
                                            }}
                                        >
                                            <Text style={styles.subtitle}>
                                                Keep InstaBook on?
                                            </Text>
                                            <Text style={styles.description}>
                                                Keep InstaBook on after
                                                concluding a session?
                                            </Text>
                                        </View>
                                        <TutredSwitch
                                            onChange={() => {
                                                setKeepInstaBookOn(
                                                    !keepInstaBookOn
                                                )
                                            }}
                                            checked={keepInstaBookOn}
                                        />
                                    </View>

                                    <TouchableOpacity
                                        style={{
                                            backgroundColor: primary,
                                            borderRadius:
                                                defaultStyles.buttonRadius,
                                            height: 40,
                                            marginTop: 15,
                                            width: isMd ? 200 : '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            alignSelf: isMd ? 'flex-end' : null,
                                        }}
                                        onPress={handleSave}
                                    >
                                        <Text
                                            style={{
                                                color: background,
                                                fontFamily:
                                                    defaultStyles.boldFont,
                                                fontSize: 16,
                                                textAlign: 'center',
                                            }}
                                        >
                                            Save
                                        </Text>
                                    </TouchableOpacity>
                                </View>

                                <View
                                    style={{
                                        display: 'flex',
                                        flexDirection: !isLg ? 'row' : 'column',
                                        width: isLg ? 330 : '100%',
                                    }}
                                >
                                    <View
                                        style={{
                                            border: '1px solid ' + border,
                                            borderRadius: defaultStyles.radius,
                                            padding: 15,
                                            width: isLg ? 330 : '100%',
                                        }}
                                    >
                                        <AntDesign
                                            name="questioncircleo"
                                            size={32}
                                            color={text}
                                            style={{ marginBottom: 10 }}
                                        />

                                        <Text style={styles.infoSubtitle}>
                                            What is InstaBook?
                                        </Text>
                                        <Text style={styles.infoDescription}>
                                            InstaBook gives you the ability to
                                            be instantly booked by students
                                            looking for on-demand help. To use
                                            InstaBook, simply hit the InstaBook
                                            button on the top header. Make sure
                                            to turn on InstaBook when you're
                                            ready to be booked!
                                        </Text>

                                        <View
                                            style={{
                                                width: '100%',
                                                height: 1,
                                                backgroundColor: border,
                                                marginTop: 15,
                                                marginBottom: 15,
                                            }}
                                        ></View>

                                        <AntDesign
                                            name="videocamera"
                                            size={32}
                                            color={text}
                                            style={{ marginBottom: 10 }}
                                        />

                                        <Text style={styles.infoSubtitle}>
                                            How does it work?
                                        </Text>
                                        <Text style={styles.infoDescription}>
                                            When a student books a session with
                                            you you will be redirected to a
                                            video session, you will start the
                                            session after you both discuss the
                                            details in the 5 minute window
                                            provided before the session starts.
                                            The standard rate will be your
                                            hourly rate multiplied by the length
                                            of the session however if the amount
                                            is less than the minimum fee you
                                            will be receive the minimum fee.
                                        </Text>

                                        <TouchableOpacity
                                            style={styles.infoLink}
                                            onPress={() => {
                                                instaBookTutorial()
                                            }}
                                        >
                                            <Text>Learn more</Text>
                                        </TouchableOpacity>
                                    </View>
                                    {isMd && <View style={{ flex: 1 }}></View>}
                                </View>
                            </View>
                        </View>
                    </View>
                    {isSm ? <Footer></Footer> : null}
                </ScrollView>
            )}
        </SafeAreaView>
    )
}
