import { Link, LinkProps } from '@mui/material'
import { useStyles } from '@utils/styles'

const AuthHeaderLink: React.FC<LinkProps> = (props) => {
    const defaultStyles = useStyles()

    return (
        <Link
            sx={{
                fontFamily: defaultStyles.titleFont,
                fontSize: {
                    sm: '1.2rem',
                },
                letterSpacing: { xs: '-.8px', md: '-1.08px' },
                cursor: 'pointer',
            }}
            color="#000"
            {...props}
            underline="none"
        />
    )
}

export default AuthHeaderLink
