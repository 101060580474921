import { useDialog } from '@contexts/dialogContext'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { useColorsContext, useStyles } from '@utils/styles'
import React from 'react'
import BecomeATutorHero from './Sections/BecomeATutorHero'
import TeachAndEarn from './Sections/TeachAndEarn'
import ExploreAI from './Sections/ExploreAI'
import TeachDifferentLanguages from './Sections/TeachDifferentLanguages'
import TeachDiverse from './Sections/TeachDiverse'
import StartTeaching from './Sections/StartTeaching'
import { Footer } from '@components/Footer'
import MoreQuestionsFooter from '@components/MoreQuestionsFooter.tsx'

const BecomeATutor: React.FC = () => {
    return (
        <Box
            sx={{
                flex: 1,
                backgroundColor: '#FCFCFC',
                width: '100%',
                overflowY: 'auto',
            }}
        >
            <Box>
                <BecomeATutorHero />
                <StartTeaching />
                <TeachDiverse />
                <TeachDifferentLanguages />
                <ExploreAI />
                <TeachAndEarn />
                <MoreQuestionsFooter />
            </Box>
            <Footer />
        </Box>
    )
}

export default BecomeATutor
