import React from 'react'
import { Box, Grid2, Typography } from '@mui/material'
import { useStyles } from '@utils/styles'

interface OfferingCountItemProps {
    icon: string
    count: string
    label: string
}

const OfferingCountItem: React.FC<OfferingCountItemProps> = ({
    count,
    label,
    icon,
}) => {
    const defaultStyles = useStyles()

    return (
        <Grid2
            size={{ xs: 6, sm: 3 }}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Box
                component="img"
                sx={{
                    height: {
                        xs: 80,
                        sm: 60,
                        lg: 80,
                    },
                    mb: 2,
                }}
                src={require(`@assets/images/landing-page/${icon}.svg`)}
            />
            <Typography
                fontFamily={defaultStyles.boldFont}
                fontSize={{
                    xs: '2rem',
                    sm: '2rem',
                    md: '2.5rem',
                }}
                letterSpacing="-1.08px"
            >
                {count}+
            </Typography>
            <Typography
                fontFamily={defaultStyles.titleFont}
                fontSize={{
                    md: '1.2rem',
                }}
                letterSpacing="-1.08px"
            >
                {label}
            </Typography>
        </Grid2>
    )
}

export default OfferingCountItem
