import { Box, Divider, Link, Typography } from '@mui/material'
import {
    NavigationProp,
    ParamListBase,
    useNavigation,
} from '@react-navigation/native'
import { useStyles } from '@utils/styles'
import { HelpArticleLink } from 'dataTypes'
import React from 'react'
import { Linking } from 'react-native'

interface Props {
    links: HelpArticleLink[]
}

const Links: React.FC<Props> = ({ links }) => {
    const navigation: NavigationProp<ParamListBase> = useNavigation()
    const defaultStyles = useStyles()

    return (
        <Box sx={{ position: 'fixed', overflowY: 'scroll' }}>
            <Typography variant="h6" fontFamily={defaultStyles.boldFont}>
                In this article
            </Typography>
            <Divider sx={{ my: 1, opacity: 1, color: '#000' }} />
            {links.map((link) => (
                <Box sx={{ mb: 1 }}>
                    <Link
                        href={link.url}
                        sx={{ fontFamily: defaultStyles.titleFont }}
                    >
                        {link.title}
                    </Link>
                </Box>
            ))}
        </Box>
    )
}

export default Links
