import { Picker } from "@react-native-picker/picker";
import { collection, orderBy, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { FlatList, ScrollView, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useMediaQuery } from "react-responsive";

// File imports
import { Footer } from "../../../components/Footer";
import { useFirebaseContext } from "../../../contexts/firebaseContext";
import { useUserContext } from "../../../contexts/userContext";
import { useColorsContext, useStyles } from "../../../utils/styles";

// Components
import CreateListingModal from "../../../components/CreateListingModal";
import { ListingCard } from "../../../components/ListingCard";
import { ListingModal } from "../../../components/ListingModal";
import { FiltersList } from "../../../components/ListingsFilter";
import { FiltersModal } from "../../../components/ListingsFiltersModal";
import { LoadingPage } from "../../../components/LoadingPage";

export const Listings = ({ navigation }) => {
	// Alert
	const customAlert = useAlert();

	// Appearance

	// Colors and styles
	const {
		darkMode,
		primary,
		primaryVariant,
		background,
		backgroundVariant,
		text,
		textVariant,
		border,
	} = useColorsContext();
	const defaultStyles = useStyles();

	// Media queries
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Firebase
	const { db } = useFirebaseContext();
	const { user, name, profile, isTutor } = useUserContext();

	// States
	const [listings, setListings] = useState([]);
	const [loading, setLoading] = useState(true);
	const [viewListing, setViewListing] = useState(false);
	const [selectedListing, setSelectedListing] = useState(null);
	const [selectedSubjects, setSelectedSubjects] = useState([]);
	const [showCreateListingModal, setShowCreateListingModal] = useState(false);
	const [sortValue, setSortValue] = useState("online");
	const [filters, setFilters] = useState([]);
	const [filtersApplied, setFiltersApplied] = useState(0);
	const [modalVisible, setModalVisible] = useState(false);

	const [subjects] = useState([
		{
			id: 0,
			subject: "Art",
			icon: "paint-brush",
		},
		{
			id: 11,
			subject: "Biology",
			icon: "heartbeat",
		},
		{
			id: 12,
			subject: "Chemistry",
			icon: "flask",
		},
		{
			id: 8,
			subject: "Computer Science",
			icon: "desktop",
		},
		{
			id: 14,
			subject: "Economics",
			icon: "money",
		},
		{
			id: 1,
			subject: "English Language Arts",
			icon: "book",
		},
		{
			id: 17,
			subject: "Environmental Science",
			icon: "leaf",
		},
		{
			id: 10,
			subject: "Geography",
			icon: "map",
		},
		{
			id: 19,
			subject: "Health Education",
			icon: "heartbeat",
		},
		{
			id: 9,
			subject: "History",
			icon: "history",
		},
		{
			id: 3,
			subject: "Social Studies",
			icon: "globe",
		},
		{
			id: 15,
			subject: "Psychology",
			icon: "male",
		},
		{
			id: 2,
			subject: "Science",
			icon: "flask",
		},
		{
			id: 16,
			subject: "Sociology",
			icon: "users",
		},
		{
			id: 4,
			subject: "Mathematics",
			icon: "calculator",
		},
		{
			id: 6,
			subject: "Music",
			icon: "music",
		},
		{
			id: 13,
			subject: "Physics",
			icon: "flask",
		},
		{
			id: 7,
			subject: "Foreign Language",
			icon: "language",
		},
		{
			id: 18,
			subject: "Philosophy",
			icon: "bookmark",
		},
	]);

	useEffect(() => {
		fetchListings();
	}, [filters, user]);

	const fetchListings = () => {
		// Firebase query
		let listingsRef = collection(db, "listings");
		var q = query(listingsRef);
		q = query(q, orderBy("createdAt", "desc"));
		var filtersApplied = 0;
		var skills = [];
		var exam = null;


		if (!isTutor && user) {
			q = query(q, where("user.uid", "==", user.uid));
		}
		// Run through filters and apply
		filters.forEach((filter) => {
			if (filter.name === "subject") {
				if (filter.value.length > 0) {
					setSelectedSubjects(filter.value.map((subject) => subject));
					q = query(q, where("subject", "in", filter.value));
					filtersApplied++;
				} else {
					setSelectedSubjects([]);
				}
			}
			if (filter.name === "skills") {
				if (filter.value.length > 0) {
					skills = filter.value;
					// q = query(q, where("skills", "in", filter.value));
					filtersApplied++;
				}
			}
			if (filter.name === "exam") {
				if (filter.value !== "all") {
					exam = filter.value;
					filtersApplied++;
				}
			}
		});


		setFiltersApplied(filtersApplied);

	};


	const navigateToChat = (chatId) => {
		navigation.navigate("Chats", {
			screen: "Chats",
			params: { chat: chatId, lo: null },
		});
	};

	const styles = StyleSheet.create({
		container: {
			backgroundColor: background,
			flex: 1,
		},
		maxWidth: {
			flex: 1,
			backgroundColor: background,
			display: "flex",
			width: "100%",
		},
		modalTitle: {
			fontSize: 18,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},
		searchBox: {
			width: "fit-content",
			height: 60,
			backgroundColor: backgroundVariant,
			borderRadius: defaultStyles.radius,
			padding: 30,
			flexDirection: "row",
			alignItems: "center",
		},
		filtersApplied: {
			position: "absolute",
			top: -10,
			left: -10,
			borderRadius: 100,
			backgroundColor: primaryVariant,
			width: 25,
			height: 25,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		filtersAppliedText: {
			color: "#fff",
			fontFamily: defaultStyles.boldFont,
			fontSize: 12,
		},
		search: {
			flex: 1,
			marginLeft: 5,
			color: text,
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
		},
		results: {
			fontFamily: defaultStyles.regularFont,
			fontSize: 14,
			color: textVariant,
			marginRight: 15,
		},
		picker: {
			borderRadius: defaultStyles.radius,
			fontFamily: defaultStyles.boldFont,
			paddingTop: 2.5,
			paddingBottom: 2.5,
			paddingLeft: 15,
			paddingRight: 15,
			color: text,
			backgroundColor: backgroundVariant,
			border: "1px solid " + border,
		},
		createListingButton: {
			justifyContent: "center",
			alignItems: "center",
			borderRadius: defaultStyles.buttonRadius,
			backgroundColor: primary,
			padding: 15,
		},
	});

	if (loading) {
		return <LoadingPage darkMode={darkMode}></LoadingPage>;
	}
	return (
		<SafeAreaView style={styles.container}>
			<View style={styles.maxWidth}>
				{selectedListing && (
					<ListingModal
						db={db}
						isTutor={isTutor}
						user={
							user
								? {
									uid: user.uid,
									name: name,
									picture: profile,
								}
								: null
						}
						navigateToChat={navigateToChat}
						modalVisible={viewListing}
						setModalVisible={setViewListing}
						listing={selectedListing}
					></ListingModal>
				)}
				{showCreateListingModal ? (
					<CreateListingModal
						visible={showCreateListingModal}
						setVisible={setShowCreateListingModal}
					></CreateListingModal>
				) : null}

				<FiltersModal
					setFilters={setFilters}
					modalVisible={modalVisible}
					setModalVisible={setModalVisible}
				></FiltersModal>


				<ScrollView
					contentContainerStyle={{
						flexGrow: 1,
						justifyContent: "space-between",
					}}
					showsVerticalScrollIndicator={false}
					style={{ flex: 1 }}
				>
					<View
						style={{
							padding: !isSm ? 15 : 45,
							paddingTop: isSm ? 45 : 0,
						}}
					>
						{/* Sorting & Results */}
						<View
							style={{
								flexDirection: "row",
								alignItems: "flex-end",
							}}
						>
							<View style={{ width: 300 }}>
								<Text
									style={{
										fontFamily: defaultStyles.boldFont,
										fontSize: 18,
										color: textVariant,
									}}
								>
									Filters
								</Text>
							</View>

							<View style={{ flex: 1 }}>
								{/* <Text style={{
                        fontFamily: defaultStyles.boldFont,
                        fontSize: 18,
                        color: textVariant,
                        marginLeft: 30
                        }}>
                        {tutors.length} Tutors
                        </Text> */}
							</View>
							{isTutor ? (
								<View
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
									}}
								>
									<Text
										style={{
											fontFamily:
												defaultStyles.regularFont,
											fontSize: 16,
											color: textVariant,
											marginRight: 10,
										}}
									>
										Sort:
									</Text>
									<Picker
										style={styles.picker}
										selectedValue={sortValue}
										onValueChange={(itemValue) =>
											setSortValue(itemValue)
										}
									>
										<Picker.Item
											label="Online"
											value="online"
										/>
										<Picker.Item
											label="Low Price"
											value="low-rate"
										/>
										<Picker.Item
											label="High Price"
											value="high-rate"
										/>
									</Picker>
								</View>
							) : (
								<View>
									<TouchableOpacity
										onPress={() => {
											if (user) {
												setShowCreateListingModal(true);
											} else {
												customAlert.error(
													"[Error Creating Listing] Must be logged in to create a listing."
												);
											}
										}}
										style={styles.createListingButton}
									>
										<Text
											style={{
												fontFamily:
													defaultStyles.boldFont,
												fontSize: 16,
												color: "#fff",
											}}
										>
											Need help? Create a listing to find
											a tutor!
										</Text>
									</TouchableOpacity>
								</View>
							)}
						</View>

						<View
							style={{
								display: "flex",
								flexDirection: !isSm ? "column" : "row",
							}}
						>
							{isMd && (
								<View
									style={[
										{
											width: 300,
											marginRight: 30,
											borderRightWidth: 1,
											borderRightColor: border,
											paddingRight: 30,
										},
										styles.shadow,
									]}
								>
									<FiltersList
										setFilters={setFilters}
									></FiltersList>
								</View>
							)}
							<FlatList
								data={listings}
								renderItem={({ item }) => (
									<TouchableOpacity
										onPress={() => {
											setSelectedListing(item);
											setViewListing(true);
										}}
									>
										<ListingCard
											listing={item}
											navigation={navigation}
										></ListingCard>
									</TouchableOpacity>
								)}
								keyExtractor={(item) => item.id}
							></FlatList>
						</View>
					</View>

					{/* Footer  */}
					{isSm ? <Footer></Footer> : null}
				</ScrollView>
			</View>
		</SafeAreaView>
	);
};
