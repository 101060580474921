// Home Screen for logged in tutor
import { A } from "@expo/html-elements";
import { Feather, FontAwesome5 } from "@expo/vector-icons";
import { Link } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
	Platform,
	SafeAreaView,
	ScrollView,
	StyleSheet,
	Text,
	TouchableOpacity,
	View,
} from "react-native";
import { Circle, Svg } from "react-native-svg";
import { useMediaQuery } from "react-responsive";
import { Footer } from "../../../components/Footer";
import { LoadingPage } from "../../../components/LoadingPage";
import { useUserContext } from "../../../contexts/userContext";
import { useColorsContext, useStyles } from "../../../utils/styles";



// Edit Payment Screen
export const Performance = () => {
	// User Context
	const { user, performance, superTutor } = useUserContext();
	const [loading, setLoading] = useState(true);

	// Colors and styles
	const {
		primary,
		primaryVariant,
		background,
		text,
		textVariant,
		border,
	} = useColorsContext();
	const defaultStyles = useStyles();



	// Responsive Breakpoints
	const isMd = useMediaQuery({ query: "(min-width: 768px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });

	// Create stylesheet
	const styles = StyleSheet.create({
		container: {
			backgroundColor: background,
			flex: 1,
		},
		maxWidth: {
			flex: 1,
			backgroundColor: background,
			display: "flex",
			flexDirection: Platform.OS === "web" ? "row" : null,
			paddingRight: 0, // Padding right is for the cover photo to stretch
			width: "100%",
			// maxWidth: Platform.OS === "web" ? 1350 : null,
			margin: Platform.OS === "web" ? "auto" : null,
		},
		mainSection: {
			flex: 1,
			padding: 45,
			paddingTop: 30,
		},
		title: {
			fontSize: 36,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},
		subtitle: {
			fontSize: 22,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},
		description: {
			fontSize: 16,
			fontFamily: defaultStyles.regularFont,
			color: textVariant,
		},
		infoTitle: {
			textAlign: "center",
			fontSize: 22,
			fontFamily: defaultStyles.titleFont,
			color: text,
		},
		infoSubtitle: {
			textAlign: "center",
			fontSize: 16,
			fontFamily: defaultStyles.boldFont,
			color: textVariant,
			marginTop: 5,
		},
		infoDescription: {
			fontSize: 16,
			fontFamily: defaultStyles.regularFont,
			color: textVariant,
			marginTop: 10,
		},
		infoLink: {
			fontSize: 14,
			fontFamily: defaultStyles.regularFont,
			color: primary,
			marginTop: 10,
		},
	});

	useEffect(() => {
		if (user) {
			// Log email notifications
			// console.log('notificationPreferences.newMessage: ', notificationPreferences.emailNotifications);
			setLoading(false);
		} else {
			// No user found
			// Redirect to login
			window.location.href = "/";
		}
	}, [user]);

	useEffect(() => {
		if (performance) {
			setLoading(false);
		}
	}, [performance]);

	const CircularProgressBar = ({
		progress,
		displayValue,
		strokeWidth,
		radius,
	}) => {
		const circumference = 2 * Math.PI * radius;
		const progressValue = circumference - (progress / 100) * circumference;

		return (
			<View
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					marginRight: 30,
					position: "relative",
				}}
			>
				<Svg width={100} height={100}>
					<Circle
						cx={radius}
						cy={radius}
						r={radius - strokeWidth / 2}
						stroke={"#0087ed0d"} // change color as needed
						strokeWidth={strokeWidth}
						fill="transparent"
					/>
					<Circle
						cx={radius}
						cy={radius}
						r={radius - strokeWidth / 2}
						stroke={primaryVariant} // change color as needed
						strokeWidth={strokeWidth}
						strokeDasharray={circumference}
						strokeDashoffset={-progressValue} // reverse the direction
						strokeLinecap="round"
						fill="transparent"
					/>
				</Svg>
				<Text
					style={{
						color: text,
						fontSize: 22,
						position: "absolute",
						zIndex: 1,
						left: 0,
						right: 0,
						textAlign: "center",
						fontFamily: defaultStyles.titleFont,
					}}
				>
					{displayValue ? displayValue : `${progress}%`}
				</Text>
			</View>
		);
	};

	const HelpModal = (title, description, onClose) => {
		return (
			<View
				style={{
					width: 450,
					backgroundColor: background,
					borderRadius: defaultStyles.radius,
					padding: 15,
					border: "1px solid" + border,
				}}
			>
				<Text
					style={{
						color: text,
						fontSize: 19,
						fontFamily: defaultStyles.titleFont,
						textAlign: "center",
						marginTop: 15,
					}}
				>
					{title}
				</Text>

				<Text
					style={{
						color: text,
						fontSize: 16,
						fontFamily: defaultStyles.regularFont,
						textAlign: "center",
						marginTop: 15,
					}}
				>
					{description}
				</Text>

				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-around",
						marginTop: 20,
					}}
				>
					<TouchableOpacity
						onPress={() => {
							onClose();
						}}
						style={{
							backgroundColor: primary,
							width: 100,
							height: 40,
							borderRadius: defaultStyles.buttonRadius,
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Text
							style={{
								color: "white",
								fontSize: 16,
								fontFamily: defaultStyles.regularFont,
								textAlign: "center",
							}}
						>
							Ok
						</Text>
					</TouchableOpacity>
				</View>
			</View>
		);
	};

	const PerformanceBox = ({
		title,
		description1,
		description2,
		help,
		performance,
		displayValue,
	}) => {
		return (
			<View
				style={{
					position: "relative",
					display: "flex",
					flexDirection: "row",
					border: "1px solid " + border,
					borderRadius: defaultStyles.radius,
					padding: 15,
					marginBottom: 15,
				}}
			>
				{/* Help icon */}
				<TouchableOpacity
					onPress={() => {
						confirmAlert({
							customUI: ({ onClose }) => {
								return HelpModal(title, help, onClose);
							},
						});
					}}
					style={{
						position: "absolute",
						zIndex: 1,
						top: 15,
						right: 15,
						width: 20,
						height: 20,
						backgroundColor: background,
						border: "1px solid " + text,
						borderRadius: 100,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Text
						style={{
							color: text,
							fontSize: 14,
							fontFamily: defaultStyles.boldFont,
						}}
					>
						?
					</Text>
				</TouchableOpacity>

				<CircularProgressBar
					strokeWidth="10"
					radius="50"
					progress={performance}
					displayValue={displayValue ? displayValue : null}
				/>

				<View
					style={{
						display: "flex",
						flexDirection: "column",
						flex: 1,
						flexWrap: "wrap",
						justifyContent: "center",
					}}
				>
					<Text style={styles.subtitle}>{title}</Text>
					<Text style={styles.description}>{description1}</Text>
					<Text style={styles.description}>{description2}</Text>
				</View>
			</View>
		);
	};

	return (
		<SafeAreaView style={styles.container}>
			{/* Main Content */}
			{loading ? (
				<LoadingPage />
			) : (
				<ScrollView>
					<View style={styles.maxWidth}>
						<View style={[styles.mainSection]}>
							{/* Title */}
							<Text
								style={{
									color: text,
									fontSize: 16,
									fontFamily: defaultStyles.regularFont,
									marginBottom: 5,
								}}
							>
								<Link
									// onMouseEnter={handlePressIn}
									// onMouseLeave={handlePressOut}
									to={"/settings"}
								>
									<Text>Settings</Text>
								</Link>{" "}
								<Text
									style={{
										fontFamily: defaultStyles.boldFont,
										color: primary,
									}}
								>
									<Feather
										name="chevron-right"
										size={15}
										color={text}
									/>{" "}
									Performance
								</Text>
							</Text>

							<Text style={styles.title}>Performance</Text>
							<View
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								<Text
									style={{
										color: text,
										fontSize: 16,
										fontFamily: defaultStyles.regularFont,
									}}
								>
									Your performance is based on your activity
									on the platform. The more active you are,
									the higher your performance will be. This
									will help you earn Super Tutor status and
									unlock perks!
								</Text>
							</View>

							<View
								style={{
									marginTop: 30,
									display: "flex",
									flexDirection: isMd
										? "row"
										: "column-reverse",
									gap: 20,
								}}
							>
								<View
									style={{
										display: "flex",
										flexDirection: "column",
										flex: 1,
									}}
								>
									{/* List of boxes for each stat */}
									<PerformanceBox
										title={"Verification"}
										description1={
											"Super Tutors: must be verified by Tutred."
										}
										description2={
											"Being verified helps students know that they can trust you and that you are a qualified tutor for their needs!"
										}
										help={
											"Submit a verification request by clicking get verified under your notifications! We will review your request once submit!"
										}
										performance={
											performance?.isVerified ? 100 : 0
										}
									/>

									<PerformanceBox
										title={"Completed sessions"}
										description1={"Super Tutors: 10"}
										description2={
											"Show students that you are a reliable experienced tutor that regularly completes sessions!"
										}
										help={
											"Accept session requests and make yourself available via InstaBook! Once you have completed 10 sessions you will be 1 step closer to earning Super Tutor!"
										}
										performance={
											performance?.completedSessions >= 10
												? 100
												: (performance?.completedSessions /
													10) *
												100
										}
										displayValue={
											performance?.completedSessions
										}
									/>

									<PerformanceBox
										title={"Response rate"}
										description1={"Super Tutors: 95%"}
										description2={
											"Super Tutors are active and responsive to students! This helps students know that you are available and ready to help them."
										}
										help={
											"Respond to session requests and messages from students as soon as possible! Make sure to keep your availability up to date and fill out your profile!"
										}
										performance={
											performance?.totalSessions > 0
												? (performance?.sessionResponses /
													performance?.totalSessions) *
												100
												: 0
										}
									/>

									<PerformanceBox
										title={"5-Star reviews"}
										description1={"Super Tutors: 95%"}
										description2={
											"You've gotten overwhelmingly positive reviews from students! Really going the extra mile to help students is what makes a Super Tutor."
										}
										help={
											"Make sure to provide the best experience possible for students! This includes being on time, being prepared, and being friendly!"
										}
										performance={
											performance?.totalRatings > 0
												? (performance?.fiveStarRatings /
													performance?.totalRatings) *
												100
												: 0
										}
									/>
								</View>

								{/* Right side includes super tutor outline */}
								{superTutor ? (
									<View
										style={{
											display: "flex",
											flexDirection: "column",
											width: isMd ? 300 : "100%",
										}}
									>
										<View
											style={{
												border: "1px solid " + border,
												borderRadius:
													defaultStyles.radius,
												padding: 15,
											}}
										>
											{/* Badge icon */}
											<View
												style={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													marginBottom: 5,
												}}
											>
												<FontAwesome5
													name="award"
													size={36}
													color={primary}
												/>
											</View>

											{/* Title */}
											<Text style={styles.infoTitle}>
												Super Tutor
											</Text>

											{/* Subtitle */}
											<Text style={styles.infoSubtitle}>
												Acheived!
											</Text>

											{/* Description */}
											<Text
												style={styles.infoDescription}
											>
												Congrats on earning Super Tutor
												status! Enjoy the perks and keep
												up the good work.
											</Text>

											{/* Link to learn about super tutor program */}
											<A href="" style={styles.infoLink}>
												Learn about the program
											</A>
										</View>
										{isMd && (
											<View style={{ flex: 1 }}></View>
										)}
									</View>
								) : (
									<View
										style={{
											display: "flex",
											flexDirection: "column",
											width: isMd ? 300 : "100%",
										}}
									>
										<View
											style={{
												border: "1px solid " + border,
												borderRadius:
													defaultStyles.radius,
												padding: 15,
											}}
										>
											{/* Badge icon */}
											<View
												style={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													marginBottom: 5,
												}}
											>
												<FontAwesome5
													name="award"
													size={36}
													color={primary}
												/>
											</View>

											{/* Title */}
											<Text style={styles.infoTitle}>
												Super Tutor
											</Text>

											{/* Subtitle */}
											<Text style={styles.infoSubtitle}>
												Performance tips
											</Text>

											{/* Description */}
											<Text
												style={styles.infoDescription}
											>
												Work towards earning your Super
												Tutor status! By becoming a
												Super Tutor you will unlock
												perks and be highlighted, this
												will help more students find you
												and book sessions!. The key
												steps to becoming a Super Tutor
												are to get your accoutn
												verified, be active and
												responsive on the platform,
												complete sessions by reaching
												out to student listings and
												making yourself available via
												InstaBook, and providing
												students with great experiences
												to earn positive reviews.
											</Text>

											{/* Link to learn about super tutor program */}
											<A href="" style={styles.infoLink}>
												Learn about the program
											</A>
										</View>
										{isMd && (
											<View style={{ flex: 1 }}></View>
										)}
									</View>
								)}
							</View>
						</View>
					</View>
					{/* Footer  */}
					{isSm ? <Footer></Footer> : null}
				</ScrollView>
			)}
		</SafeAreaView>
	);
};
